/**
 * ApprovalView.js - React component ApprovalView
 *
 * Copyright 2023 Taito United Oy
 * All rights reserved.
 */

import PropTypes from 'prop-types';

import React from 'react';
import AuthenticatedView from '../AuthenticatedView/AuthenticatedView';
import Header from '../Header/Header';
import Messages from '../Messages/Messages';
import DownloadSummaryModal from '../DownloadSummaryModal/DownloadSummaryModal';
import ApprovalHierarchy from '../ApprovalHierarchy/ApprovalHierarchy';
import PackageInformationPanel from '../PackageInformationPanel/PackageInformationPanel';
import ProductInformationPanel from '../ProductInformationPanel/ProductInformationPanel';
import { FormattedMessage } from 'react-intl';
import ProductInformationModal from '../ProductInformationModal/ProductInformationModal';
import AddFileModal from '../AddFileModal/AddFileModal';

class ApprovalView extends React.Component {
  static propTypes = {
    rtStore: PropTypes.object.isRequired,
    messageStore: PropTypes.object.isRequired,
    packageStore: PropTypes.object.isRequired,
    productStore: PropTypes.object.isRequired,
    logStore: PropTypes.object.isRequired,
    rtActionCreators: PropTypes.object.isRequired,
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    productActionCreators: PropTypes.object.isRequired,
    fileActionCreators: PropTypes.object.isRequired,
    logActionCreators: PropTypes.object.isRequired,
    packageID: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this._getStoreData(),
      productPanel: false,
      package: null,
      open: true,
    };
  }

  componentDidMount() {
    this._rtStoreSubscription = this.props.rtStore.addListener(
      this._onStoreChange
    );

    this._packageStoreSubscription = this.props.packageStore.addListener(
      this._onStoreChange
    );

    this._productStoreSubscription = this.props.productStore.addListener(
      this._onStoreChange
    );

    this._logStoreSubscription = this.props.logStore.addListener(
      this._onStoreChange
    );

    this._companyStoreSubscription = this.props.companyStore.addListener(
      this._onStoreChange
    );
  }

  componentWillUnmount() {
    this._rtStoreSubscription.remove();
    this._packageStoreSubscription.remove();
    this._productStoreSubscription.remove();
    this._logStoreSubscription.remove();
    this._companyStoreSubscription.remove();
  }

  _onStoreChange = () => {
    this.setState({ ...this._getStoreData() });
  };

  _getStoreData = () => {
    return {
      packageState: this.props.packageStore.getState(),
      productState: this.props.productStore.getState(),
      rtState: this.props.rtStore.getState(),
      logState: this.props.logStore.getState(),
      companyState: this.props.companyStore.getState(),
    };
  };

  _productPanel = (pkg, open = true) => {
    this.setState({
      productPanel: true,
      package: pkg,
      open,
    });
  };

  _packagePanel = (pkg, open = true) => {
    this.setState({
      productPanel: false,
      package: pkg,
      open,
    });
  };

  _openSidePanel = (open) => {
    this.setState({ open });
  };

  _getSidePanel = (productPanel, productState, packageState, rtState) => {
    if (!this.state.open) {
      return null;
    }

    if (productPanel) {
      const productKey = productState.product
        ? productState.product.id
        : 'loading';
      const key = `${this.state.package}-${productKey}`;
      return (
        <ProductInformationPanel
          key={key}
          package={this.state.package}
          product={productState.product}
          productLoading={productState.productLoading}
          log={this.state.logState.log}
          externalProduct={productState.externalProduct}
          globalSearch={[productState.externalProduct]}
          packageActionCreators={this.props.packageActionCreators}
          productActionCreators={this.props.productActionCreators}
          fileActionCreators={this.props.fileActionCreators}
          messageActionCreators={this.props.messageActionCreators}
          logActionCreators={this.props.logActionCreators}
          safetyData={this.state.productState.safetyData.fi}
          approvers={this.state.companyState.approvers}
          user={rtState.user}
        />
      );
    }

    return (
      <PackageInformationPanel
        key={packageState.package ? packageState.package.id : null}
        package={packageState.package}
        packageLoading={packageState.packageLoading}
        packageSummaryLoading={packageState.packageSummaryLoading}
        packageApprovalReportLoading={packageState.packageApprovalReportLoading}
        log={this.state.logState.log}
        user={rtState.user}
        productActionCreators={this.props.productActionCreators}
        packageActionCreators={this.props.packageActionCreators}
        fileActionCreators={this.props.fileActionCreators}
        activePackage={packageState.activePackage}
        openMobileMenu={packageState.openMobileMenu}
        chemicalView={true}
        packageChemicalChartInfo={packageState.packageChemicalChartInfo}
        packageChemicalChartInfoLoading={
          packageState.packageChemicalChartInfoLoading
        }
        approvers={this.state.companyState.approvers}
      />
    );
  };

  _getContent = () => {
    const productPanel = this.state.productPanel;
    const rtState = this.state.rtState;
    const packageState = this.state.packageState;
    const productState = this.state.productState;

    return (
      <div className='container-fluid'>
        <Header
          user={rtState.user}
          packages={packageState.packageSearch}
          searchLoading={packageState.packageSearchLoading}
          packageActionCreators={this.props.packageActionCreators}
          rtActionCreators={this.props.rtActionCreators}
        />

        <Messages
          messageStore={this.props.messageStore}
          messageActionCreators={this.props.messageActionCreators}
        />

        <div className='ApprovalView-content row'>
          <DownloadSummaryModal
            fileActionCreators={this.props.fileActionCreators}
            packageActionCreators={this.props.packageActionCreators}
            package={packageState.package}
            packageSummaryLoading={packageState.packageSummaryLoading}
          />

          <ProductInformationModal
            productActionCreators={this.props.productActionCreators}
            product={productState.product}
          />

          <ApprovalHierarchy
            packageID={this.props.packageID}
            packages={packageState.packages}
            packagesLoading={packageState.packagesLoading}
            package={packageState.package}
            packageLoading={packageState.packageLoading}
            onSelectPackage={this._packagePanel}
            product={productState.product}
            productLoading={productState.productLoading}
            products={packageState.products}
            packageProducts={packageState.packageProducts}
            productsLoading={packageState.productsLoading}
            onSelectProduct={this._productPanel}
            isProductPanel={this.state.productPanel}
            archivedCount={packageState.archivedCount}
            user={rtState.user}
            messageActionCreators={this.props.messageActionCreators}
            packageActionCreators={this.props.packageActionCreators}
            productActionCreators={this.props.productActionCreators}
            logActionCreators={this.props.logActionCreators}
            openSidePanel={this._openSidePanel}
            activePackage={packageState.activePackage}
            approverIds={productState.approverIds}
          />

          {this._getSidePanel(
            productPanel,
            productState,
            packageState,
            rtState
          )}
        </div>
      </div>
    );
  };

  render() {
    let content = null;

    if (
      this.state.rtState.error !== null ||
      this.state.packageState.error !== null
    ) {
      /* eslint-disable */
      content = (
        <FormattedMessage
          id='error'
          description='Generic error'
          defaultMessage='Valitettavasti palvelussa tapahtui odottamaton virhe, {logout} ja yritä myöhemmin uudestaan!'
          values={{
            logout: <a href='/logout'>kirjaudu ulos</a>,
          }}
        />
      );
    } else {
      content = this._getContent();
    }

    return (
      <div className='ApprovalView'>
        {content}
        <AddFileModal
          product={this.state.productState.product}
          fileActionCreators={this.props.fileActionCreators}
          messageActionCreators={this.props.messageActionCreators}
        />
      </div>
    );
  }
}

export default AuthenticatedView(ApprovalView);
