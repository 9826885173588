// --  STYLES --
/**
 * When you import/require CSS or SASS, webpack will magically handle it when
 * it is configured to do so.
 * Because webpack importing other resources than JavaScript is a bit magical
 * and we want to minimize magic on production code, this is the only scss
 * import on the code. The app.scss file imports the rest via sass imports.
 */
import './app.scss';

// --  SCRIPTS --
// jQuery first then Bootstrap
// import './static/js/jquery-2.1.4.min.js';
import './static/bootstrap/dist/js/bootstrap.min.js';

// -- POLYFILLS --
import '@babel/polyfill';
import 'intl';

// --  EXTERNAL LIBRARIES --
import { Dispatcher } from 'flux';
import { IntlProvider } from 'react-intl';
import ReactDOM from 'react-dom';
import { setDefaultOptions } from 'date-fns';
import { fi } from 'date-fns/locale';
// pagejs is a router
import page from 'page';
import query from './utils/query';
import analytics from 'react-ga';

// --  UTILITIES --
import ApiUtils from './utils/ApiUtils';

// --  REACT & FLUX COMPONENTS, STORES AND ACTIONS --
import HomeView from './components/HomeView/HomeView.jsx';
import ChemicalView from './components/ChemicalView/ChemicalView';
import ApprovalView from './components/ApprovalView/ApprovalView';
import NotFoundView from './components/NotFoundView/NotFoundView';
import LandingView from './components/LandingView/LandingView';
import PackageInformationView from './components/PackageInformationView/PackageInformationView';
import PackageEditView from './components/PackageEditView/PackageEditView';
import PackageProductsView from './components/PackageProductsView/PackageProductsView';
import PackageRightsView from './components/PackageRightsView/PackageRightsView';
import CompanyRightsView from './components/CompanyRightsView/CompanyRightsView';
import ProductBrowseView from './components/ProductBrowseView/ProductBrowseView';
import ProductLinkView from './components/ProductLinkView/ProductLinkView';
import ManualProductsView from './components/ManualProductsView/ManualProductsView';
import LogView from './components/LogView/LogView';
import StatsView from './components/StatsView/StatsView';
import BulletinView from './components/BulletinView/BulletinView';
import BulletinEditView from './components/BulletinEditView/BulletinEditView';
import ConstructionPhaseView from './components/ConstructionPhaseView/ConstructionPhaseView';
import PublicChemicalsView from './components/PublicChemicalsView/PublicChemicalsView';
import ReportsView from './components/ReportsView/ReportsView.jsx';
import RTStore from './stores/RTStore';
import MessageStore from './stores/MessageStore';
import PackageStore from './stores/PackageStore';
import CompanyStore from './stores/CompanyStore';
import ProductStore from './stores/ProductStore';
import LogStore from './stores/LogStore';
import StatsStore from './stores/StatsStore';
import BulletinStore from './stores/BulletinStore';
import RTActionCreators from './actions/RTActionCreators';
import MessageActionCreators from './actions/MessageActionCreators';
import PackageActionCreators from './actions/PackageActionCreators';
import CompanyActionCreators from './actions/CompanyActionCreators';
import ProductActionCreators from './actions/ProductActionCreators';
import FileActionCreators from './actions/FileActionCreators';
import LogActionCreators from './actions/LogActionCreators';
import StatsActionCreators from './actions/StatsActionCreators';
import BulletinActionCreators from './actions/BulletinActionCreators';
import FavouritesActionCreators from './actions/FavouritesActionCreators';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { initInjector } from './stores/Injector';

setDefaultOptions({ locale: fi });

const apiBaseUrl = process.env.API_BASE_PATH;
const dispatcher = new Dispatcher();
const apiUtils = new ApiUtils(apiBaseUrl);

const rtStore = new RTStore(dispatcher);
const messageStore = new MessageStore(dispatcher);
const packageStore = new PackageStore(dispatcher);
const companyStore = new CompanyStore(dispatcher);
const productStore = new ProductStore(dispatcher);
const logStore = new LogStore(dispatcher);
const statsStore = new StatsStore(dispatcher);
const bulletinStore = new BulletinStore(dispatcher);

/* eslint-disable max-len */
const rtActionCreators = new RTActionCreators(dispatcher, apiUtils);
const messageActionCreators = new MessageActionCreators(dispatcher, apiUtils);
const packageActionCreators = new PackageActionCreators(dispatcher, apiUtils);
const companyActionCreators = new CompanyActionCreators(dispatcher, apiUtils);
const productActionCreators = new ProductActionCreators(dispatcher, apiUtils);
const fileActionCreators = new FileActionCreators(dispatcher, apiUtils);
const logActionCreators = new LogActionCreators(dispatcher, apiUtils);
const statsActionCreators = new StatsActionCreators(dispatcher, apiUtils);
const bulletinActionCreators = new BulletinActionCreators(dispatcher, apiUtils);
const favouritesActionCreators = new FavouritesActionCreators(
  dispatcher,
  apiUtils
);

const stores = {
  rtStore,
  messageStore,
  packageStore,
  companyStore,
  productStore,
  logStore,
  statsStore,
  bulletinStore,
};

const actionCreators = {
  rtActionCreators,
  messageActionCreators,
  packageActionCreators,
  companyActionCreators,
  productActionCreators,
  fileActionCreators,
  logActionCreators,
  statsActionCreators,
  bulletinActionCreators,
  favouritesActionCreators,
};

// Injector allows to inject stores and action creators to components
// without having to give them through the View components. Beneficial to
// Header component for example, since is used in every view.
initInjector(stores, actionCreators);

/* eslint-enable max-len */

// Log all dispatches when debugging is on.
// if (process.env.DEBUG) {
dispatcher.register((action) => {
  console.log('DISPATCHER', action);
});
// }

analytics.initialize(process.env.GA_TRACKING_ID, { debug: process.env.DEBUG });

// -- ROUTES! --
/**
 * Set the base url, e.g. /blog or /app. Read from ENVs in accordance to
 * "12 factor app" specifications.
 */
page.base(process.env.APP_BASE_PATH);

// Middleware for clearing messages and search on page change
page((context, next) => {
  analytics.pageview(context.canonicalPath);
  // messageActionCreators.clearMessages();
  productActionCreators.clearProduct();
  next();
});

page('/reports', () => {
  rtActionCreators.getCurrentUser();
  packageActionCreators.getReportData({ search: '', page: 1, svhc: false });
  productActionCreators.getSafetyData('fi');
  productActionCreators.getSafetyData('en');

  const props = {
    rtStore,
    packageStore,
    productStore,
    rtActionCreators,
    packageActionCreators,
    fileActionCreators,
  };

  // Using ES6/React spread syntax: ...variable
  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <ReportsView {...props} />
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/chemicalchart/:uuid', ({ params }) => {
  packageActionCreators.getChemicalChartData(params.uuid);
  productActionCreators.getSafetyData('fi');
  productActionCreators.getSafetyData('en');

  const props = {
    uuid: params.uuid,
    packageStore,
    productStore,
    fileActionCreators,
    productActionCreators,
  };

  // Using ES6/React spread syntax: ...variable
  ReactDOM.render(
    <PublicChemicalsView {...props} {...params} />,
    document.getElementById('rakennustieto-client')
  );
});

/**
 * NOTE:
 * All React components that require authentication are wrapped to
 * AuthenticatedView component which redirects to /login when user has not
 * logged in (or the log in has expired).
 */

page('/', (ctx) => {
  /**
   * Fetch view data for this view, i.e. those items that user does not need to
   * click for. There are two cases that we need to consider:
   *
   * 1) This is the first time user visits this view.
   *       In this case we need to fetch the data.
   *
   * 2) User has visited this view before, the data is already in client store.
   *       In this case we still need to check whether the data is up-to-date.
   *
   * Hence we are okay triggering API requests every time. The API layer should
   * implement some kind of caching, depending on the technology choices and
   * how the application data is modelled. For REST this could be for example
   * HTTP ETags.
   */
  rtActionCreators.getCurrentUser();
  // packageActionCreators.clearPackage();
  packageActionCreators.getPackages();
  packageActionCreators.getArchivedCount();
  productActionCreators.clearProduct();
  bulletinActionCreators.getRelevantBulletins();
  companyActionCreators.getCompanyApproverOptions();

  // Props done this way for convenience, avoids rtStore={rtStore} verbosity.
  const props = {
    rtStore,
    rtActionCreators,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
    productStore,
    productActionCreators,
    logStore,
    logActionCreators,
    bulletinStore,
    bulletinActionCreators,
    fileActionCreators,
    companyStore,
  };

  const params = query.parse(ctx.querystring);
  if (params.package) {
    packageActionCreators.getPackageProducts(params.package);
    logActionCreators.getPackageLog(params.package);
    packageActionCreators.getPackage(params.package);
  }
  productActionCreators.getSafetyData();

  // Using ES6/React spread syntax: ...variable
  ReactDOM.render(
    <DndProvider backend={HTML5Backend}>
      <IntlProvider locale='fi' defaultLocale='fi'>
        <HomeView {...props} params={params} />
      </IntlProvider>
    </DndProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/login', (ctx) => {
  // Get query parameters
  const params = query.parse(ctx.querystring);
  // Fetch/refresh view data
  rtActionCreators.getCurrentUser();

  const props = {
    rtStore,
    rtActionCreators,
    messageStore,
    messageActionCreators,
  };

  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <LandingView {...props} inviteToken={params.token} />
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/authorized', (ctx) => {
  const params = query.parse(ctx.querystring);
  rtActionCreators.onAuthorization(params.token);
});

page('/logout', () => {
  rtActionCreators.logout();
});

page('/products/manual', () => {
  // Fetch/refresh view data
  rtActionCreators.getCurrentUser();
  packageActionCreators.getPackages();
  productActionCreators.getSafetyData();
  companyActionCreators.getCompanyChemicalProducts();

  const props = {
    rtStore,
    rtActionCreators,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
    productStore,
    productActionCreators,
    companyStore,
    companyActionCreators,
    fileActionCreators,
    favouritesActionCreators,
  };

  ReactDOM.render(
    <DndProvider backend={HTML5Backend}>
      <IntlProvider locale='fi' defaultLocale='fi'>
        <ManualProductsView {...props} />
      </IntlProvider>
    </DndProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/products/browse', () => {
  // Fetch/refresh view data
  rtActionCreators.getCurrentUser();
  packageActionCreators.getPackages();
  productActionCreators.getSafetyData();

  const props = {
    rtStore,
    rtActionCreators,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
    productStore,
    productActionCreators,
    fileActionCreators,
    favouritesActionCreators,
  };

  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <ProductBrowseView {...props} />
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/products/link', () => {
  // Fetch/refresh view data
  rtActionCreators.getCurrentUser();

  const props = {
    rtStore,
    rtActionCreators,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
    productStore,
    productActionCreators,
  };

  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <ProductLinkView {...props} />
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/package/:id', ({ params }) => {
  // Fetch/refresh view data
  rtActionCreators.getCurrentUser();
  packageActionCreators.getPackage(params.id);
  packageActionCreators.getPackageTree(params.id);
  packageActionCreators.getRights(params.id);
  logActionCreators.getPackageLog(params.id);

  const props = {
    rtStore,
    rtActionCreators,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
    productStore,
    productActionCreators,
    logStore,
    logActionCreators,
  };

  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <PackageInformationView {...props} />
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/package/:id/edit', ({ params }) => {
  // Fetch/refresh view data
  rtActionCreators.getCurrentUser();
  packageActionCreators.getPackage(params.id);

  const props = {
    rtStore,
    rtActionCreators,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
  };

  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <PackageEditView {...props} />
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/package/:id/phases', ({ params }) => {
  // Fetch/refresh view data
  rtActionCreators.getCurrentUser();
  packageActionCreators.getPackage(params.id);
  packageActionCreators.getPackageTree(params.id);

  const props = {
    rtStore,
    rtActionCreators,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
  };

  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <ConstructionPhaseView {...props} />
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/package/:id/products', ({ params }) => {
  rtActionCreators.getCurrentUser();
  packageActionCreators.getPackage(params.id);
  packageActionCreators.getPackageTree(params.id);
  packageActionCreators.getPackageProducts(params.id);
  productActionCreators.getSafetyData();
  companyActionCreators.getCompanyChemicalProducts();
  companyActionCreators.getCompanyApproverOptions();

  const props = {
    rtStore,
    rtActionCreators,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
    productStore,
    productActionCreators,
    companyStore,
    companyActionCreators,
    fileActionCreators,
    logStore,
    logActionCreators,
    favouritesActionCreators,
  };

  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <DndProvider backend={HTML5Backend}>
        <PackageProductsView {...props} />
      </DndProvider>
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/package/:id/chemicals', (ctx) => {
  rtActionCreators.getCurrentUser();
  packageActionCreators.getPackageTree(ctx.params.id);
  packageActionCreators.getArchivedCount();
  productActionCreators.clearProduct();
  companyActionCreators.getCompanyApproverOptions();

  const props = {
    rtStore,
    rtActionCreators,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
    productStore,
    productActionCreators,
    logStore,
    companyStore,
    logActionCreators,
    fileActionCreators,
    packageID: Number(ctx.params.id),
  };

  const params = query.parse(ctx.querystring);
  if (params.package) {
    packageActionCreators.getPackageProducts(params.package);
    logActionCreators.getPackageLog(params.package);
    packageActionCreators.getPackage(params.package);
  }
  productActionCreators.getSafetyData();

  ReactDOM.render(
    <DndProvider backend={HTML5Backend}>
      <IntlProvider locale='fi' defaultLocale='fi'>
        <ChemicalView {...props} params={params} />
      </IntlProvider>
    </DndProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/package/:id/approval', (ctx) => {
  rtActionCreators.getCurrentUser();
  packageActionCreators.getPackageTree(ctx.params.id);
  packageActionCreators.getArchivedCount();
  productActionCreators.clearProduct();
  companyActionCreators.getCompanyApproverOptions();

  const props = {
    rtStore,
    rtActionCreators,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
    productStore,
    productActionCreators,
    logStore,
    logActionCreators,
    fileActionCreators,
    packageID: Number(ctx.params.id),
    companyStore,
  };

  const params = query.parse(ctx.querystring);
  if (params.package) {
    packageActionCreators.getPackageProducts(params.package);
    logActionCreators.getPackageLog(params.package);
    packageActionCreators.getPackage(params.package);
  }
  productActionCreators.getSafetyData();

  ReactDOM.render(
    <DndProvider backend={HTML5Backend}>
      <IntlProvider locale='fi' defaultLocale='fi'>
        <ApprovalView {...props} params={params} />
      </IntlProvider>
    </DndProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/package/:id/rights', ({ params }) => {
  rtActionCreators.getCurrentUser();
  packageActionCreators.getPackage(params.id);
  packageActionCreators.getRights(params.id);

  window.scrollTo(0, 0);

  const props = {
    rtStore,
    rtActionCreators,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
  };

  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <PackageRightsView {...props} />
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/rights', () => {
  rtActionCreators.getCurrentUser();
  companyActionCreators.getCurrentCompanyRights();

  const props = {
    rtStore,
    rtActionCreators,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
    companyStore,
    companyActionCreators,
  };

  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <CompanyRightsView {...props} />
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/logs', () => {
  rtActionCreators.getCurrentUser();
  logActionCreators.getLogs();

  const props = {
    rtStore,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
    logStore,
  };

  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <LogView {...props} />
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/stats', () => {
  rtActionCreators.getCurrentUser();
  statsActionCreators.getStats();

  const props = {
    rtStore,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
    statsStore,
    statsActionCreators,
  };

  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <StatsView {...props} />
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/bulletins', () => {
  rtActionCreators.getCurrentUser();
  bulletinActionCreators.getValidBulletins();
  const props = {
    rtStore,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
    bulletinStore,
    bulletinActionCreators,
  };

  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <BulletinView {...props} />
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('/bulletins/edit', () => {
  rtActionCreators.getCurrentUser();
  bulletinActionCreators.getAllBulletins();
  const props = {
    rtStore,
    messageStore,
    messageActionCreators,
    packageStore,
    packageActionCreators,
    bulletinStore,
    bulletinActionCreators,
  };

  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <BulletinEditView {...props} />
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page('*', () => {
  ReactDOM.render(
    <IntlProvider locale='fi' defaultLocale='fi'>
      <NotFoundView />
    </IntlProvider>,
    document.getElementById('rakennustieto-client')
  );
});

page.start();
