import CommonActionCreators from './CommonActionCreators';
import ActionTypes from './ActionTypes';
import ErrorTypes from '../../shared/ErrorTypes.mjs';
import { getProductData } from '../utils/productUtils';

import { defineMessages } from 'react-intl';
import page from 'page';

const messages = defineMessages({
  packageViewNoAccess: {
    id: 'package-view-no-access',
    description: 'No access to view package',
    defaultMessage:
      'Sinulla ei ole oikeuksia kyseiseen urakkaan.' +
      ' Kokeile päivittää sivu.',
  },

  cantModifyRTMainUser: {
    id: 'cant-modify-rt-main-user',
    description: 'cant modify RT main user rights',
    defaultMessage: 'Et voi muokata ' + 'RT pääkäyttäjän THP-oikeuksia.',
  },

  giveApproveRightsNoAccess: {
    id: 'give-approve-rights-no-access',
    description: 'Try to give approve rights wihtout access',
    defaultMessage:
      'Sinulla ei ole oikeuksia antaa ' +
      'oikeutta tuotekelpoisuushyväksyntään.',
  },

  packageViewNotFound: {
    id: 'package-view-not-found',
    description: 'Tried to view non-existing package',
    defaultMessage: 'Urakkaa ei löytynyt!',
  },

  createPackageSuccess: {
    id: 'create-package-success',
    description: 'Message shown for successful package creation',
    defaultMessage: '"{name}" luotiin onnistuneesti!',
  },

  createPackageNoAccess: {
    id: 'create-package-no-access',
    description: 'No access to package parent when creating package',
    defaultMessage: 'Sinulla ei ole oikeuksia luoda tätä urakkaa!',
  },

  createPackageNoLicense: {
    id: 'create-package-no-license',
    description: 'User has no license to create packages',
    defaultMessage:
      'Sinulla ei ole tarvittavaa käyttöoikeutta ' + 'urakan luomiseen!',
  },

  createPackageParentNotFound: {
    id: 'create-package-parent-not-found',
    description: 'Package parent was not found',
    defaultMessage:
      'Aliurakan työmaata ei ole olemassa.' + ' Kokeile päivittää sivu.',
  },

  createPackageArchived: {
    id: 'create-package-archived',
    description: 'Package ancestor is archived when creating',
    defaultMessage:
      'Et voi luoda arkistoituun työmaahan urakkaa!' +
      ' Kokeile päivittää sivu.',
  },

  createPackageExistsOnLevel: {
    id: 'create-package-exists-on-level',
    description: 'Package with this name already exists in this level',
    defaultMessage: 'Urakassa on jo samanniminen aliurakka.',
  },

  editPackageParentNotFound: {
    id: 'edit-package-parent-not-found',
    description: 'Package parent was not found when editing',
    defaultMessage:
      'Aliurakan työmaata ei ole olemassa.' + ' Kokeile päivittää sivu.',
  },

  editPackageNotFound: {
    id: 'edit-package-not-found',
    description: 'Package not found when editing',
    defaultMessage: 'Urakkaa ei ole olemassa! Kokeile päivittää sivu.',
  },

  editPackageNoAccess: {
    id: 'edit-package-no-access',
    description: 'User does not have the right to edit a package',
    defaultMessage: 'Sinulla ei ole oikeuksia muokata tätä urakkaa!',
  },

  editPackageSuccess: {
    id: 'edit-package-success',
    description: 'Updated package successfully',
    defaultMessage: 'Päivitys onnistui!',
  },

  editPackageArchived: {
    id: 'edit-package-archived',
    description: 'Package ancestor is archived when editing',
    defaultMessage:
      'Et voi muokata arkistoidun työmaan urakkaa!' +
      ' Kokeile päivittää sivu.',
  },

  editForbiddenMetaField: {
    id: 'edit-forbidden-meta-field',
    description: 'Unexpected detail field when editing package',
    defaultMessage: 'Urakkaan ei voi päivittää määrittämääsi lisätietokenttää',
  },

  completePackageParentNotFound: {
    id: 'complete-package-parent-not-found',
    description: 'Package parent was not found when completing',
    defaultMessage:
      'Aliurakan työmaata ei ole olemassa.' + ' Kokeile päivittää sivu.',
  },

  completePackageNotFound: {
    id: 'complete-package-not-found',
    description: 'Package not found when completing',
    defaultMessage: 'Urakkaa ei ole olemassa! Kokeile päivittää sivu.',
  },

  completePackageNoAccess: {
    id: 'complete-package-no-access',
    description: 'User does not have the right to complete a package',
    defaultMessage: 'Sinulla ei ole oikeuksia merkata tätä urakkaa valmiiksi!',
  },

  completePackageArchived: {
    id: 'complete-package-archived',
    description: 'Package ancestor is archived when completing',
    defaultMessage:
      'Et voi muokata arkistoidun työmaan urakkaa!' +
      ' Kokeile päivittää sivu.',
  },

  subpackagesNotComplete: {
    id: 'subpackages-not-complete',
    description: 'Package has a subpackage that has not been completed',
    defaultMessage:
      'Et voi merkitä urakkaa valmiiksi, koska sillä on ' +
      'keskeneräisiä aliurakoita! Kokeile päivittää sivu.',
  },

  packageRemoveSuccess: {
    id: 'package-remove-success',
    description: 'Package was removed successfully',
    defaultMessage: 'Urakka "{name}" poistettiin!',
  },

  packageRemoveNotFound: {
    id: 'package-remove-not-found',
    description: 'Tried to remove non-existing package',
    defaultMessage: 'Urakkaa ei löytynyt!',
  },

  packageRemoveNoAccess: {
    id: 'package-remove-no-access',
    description: 'No access to remove package',
    defaultMessage:
      'Sinulla ei ole oikeuksia poistaa urakoita.' + ' Kokeile päivittää sivu.',
  },

  packageRemoveArchived: {
    id: 'package-remove-archived',
    description: 'Package ancestor is archived when removing',
    defaultMessage:
      'Et voi poistaa arkistoidun työmaan urakkaa!' +
      ' Kokeile päivittää sivu.',
  },

  packageRemoveNotEmpty: {
    id: 'package-remove-not-empty',
    description: 'Package is not empty when trying to remove',
    defaultMessage:
      'Urakalla on vielä aliurakoita tai tuotteita' +
      ' ja urakkaa, joka ei ole tyhjä ei voi poistaa.' +
      ' Kokeile päivittää sivu.',
  },

  packageArchiveSuccess: {
    id: 'package-archive-success',
    description: 'Package was archived successfully',
    defaultMessage: 'Työmaa "{name}" arkistoitiin!',
  },

  packageArchiveNotFound: {
    id: 'package-archive-not-found',
    description: 'Tried to archive non-existing package',
    defaultMessage: 'Urakkaa ei löytynyt!' + ' Kokeile päivittää sivu.',
  },

  packageArchiveNoAccess: {
    id: 'package-archive-no-access',
    description: 'No access to archive package',
    defaultMessage: 'Sinulla ei ole oikeuksia arkistoida urakoita.',
  },

  packageArchiveNotRoot: {
    id: 'package-archive-not-root',
    description: 'Package is not root when trying to archive',
    defaultMessage: 'Urakka ei ole työmaa!' + ' Kokeile päivittää sivu.',
  },

  packageArchiveIsArchived: {
    id: 'package-archive-is-archived',
    description: 'Package is already archived when archiving',
    defaultMessage: 'Urakka on jo arkistoitu!' + ' Kokeile päivittää sivu.',
  },

  packageUnarchiveSuccess: {
    id: 'package-unarchive-success',
    description: 'Package was unarchived successfully',
    defaultMessage: 'Työmaa "{name}" palautettiin aktiiviseksi!',
  },

  packageUnarchiveNotFound: {
    id: 'package-unarchive-not-found',
    description: 'Tried to unarchive non-existing package',
    defaultMessage: 'Urakkaa ei löytynyt!' + ' Kokeile päivittää sivu.',
  },

  packageUnarchiveNoAccess: {
    id: 'package-unarchive-no-access',
    description: 'No access to unarchive package',
    defaultMessage:
      'Sinulla ei ole oikeuksia kyseiseen urakkaan.' +
      ' Kokeile päivittää sivu.',
  },

  packageUnarchiveNotRoot: {
    id: 'package-unarchive-not-root',
    description: 'Package is not root when trying to unarchive',
    defaultMessage: 'Urakka ei ole työmaa!' + ' Kokeile päivittää sivu.',
  },

  packageUnarchiveNotArchived: {
    id: 'package-unarchive-is-archived',
    description: 'Package is not archived when unarchiving',
    defaultMessage: 'Urakkaa ei ole arkistoitu!' + ' Kokeile päivittää sivu.',
  },

  addProductSuccess: {
    id: 'add-product-success',
    description: 'Product was added to package successfully',
    defaultMessage: 'Tuote "{name}" lisättiin suunnitelmaan!',
  },

  addProductPackageNotFound: {
    id: 'add-product-package-not-found',
    description: 'Package not found while trying to add product',
    defaultMessage: 'Virheellinen urakka! Kokeile päivittää sivu.',
  },

  addProductPackageNoAccess: {
    id: 'add-product-package-no-access',
    description: 'User does not have the right to add products',
    defaultMessage:
      'Sinulla ei ole oikeuksia' +
      ' lisätä tuotteita tämän urakan suunnitelmaan!',
  },

  addProductPackageArchived: {
    id: 'add-product-package-archived',
    description: 'Package is archived when trying to add product',
    defaultMessage:
      'Et voi lisätä arkistoituun urakkaan tuotteita!' +
      ' Kokeile päivittää sivu.',
  },

  addProductPackageCompleted: {
    id: 'add-product-package-completed',
    description: 'Package is completed when trying to add product',
    defaultMessage:
      'Et voi lisätä valmiiseen urakkaan tuotteita!' +
      ' Kokeile päivittää sivu.',
  },

  productNotFound: {
    id: 'product-not-found',
    description: 'Tried to add non-existing product',
    defaultMessage: 'Tuotetta ei ole olemassa! Kokeile päivittää sivu.',
  },

  productAlreadyExists: {
    id: 'product-already-exists',
    description: 'Product already exists for package',
    defaultMessage: 'Tuote on jo lisättynä suunnitelmaan!',
  },

  productNameEmpty: {
    id: 'product-name-empty',
    description: 'Product name was empty',
    defaultMessage: 'Tuotteen nimi ei voi olla tyhjä!',
  },

  productManufacturerEmpty: {
    id: 'product-manufacturer-empty',
    description: 'Product manufacturer was empty',
    defaultMessage: 'Tuotteen yritys ei voi olla tyhjä!',
  },

  instaddProductSuccess: {
    id: 'instadd-product-success',
    description: 'Product was added and installed successfully',
    defaultMessage: 'Tuote "{name}" merkittiin asennetuksi!',
  },

  invitationSuccess: {
    id: 'invitation-success',
    description: 'User was sent an invitation successfully',
    defaultMessage: 'Käyttäjälle {name} lähetettiin kutsu!',
  },

  invitePackageNotFound: {
    id: 'invite-package-not-found',
    description: 'Package not found while trying to invite user',
    defaultMessage: 'Virheellinen urakka! Kokeile päivittää sivu.',
  },

  invitePackageNoAccess: {
    id: 'invite-package-no-access',
    description: 'User does not have the right to invite users',
    defaultMessage: 'Sinulla ei ole oikeuksia kutsua käyttäjiä tähän urakkaan!',
  },

  inviteAlreadyHasRights: {
    id: 'invite-already-has-rights',
    description: 'Tried to invite user who already has rights',
    defaultMessage: 'Käyttäjällä on jo oikeudet tähän urakkaan!',
  },

  inviteAlreadyExists: {
    id: 'invite-already-exists',
    description: 'User has already been invited',
    defaultMessage: 'Käyttäjälle on jo lähetetty kutsu tähän urakkaan!',
  },

  inviteMailError: {
    id: 'invite-mail-error',
    description: 'An error happened while sending invitation mail',
    defaultMessage:
      'Virhe lähetettäessä kutsua!' + ' Koita myöhemmin uudelleen.',
  },

  transferSuccess: {
    id: 'transfer-success',
    description: 'User was sent a transfer email successfully',
    defaultMessage: 'Käyttäjälle {name} lähetettiin vahvistus!',
  },

  transferPackageNotFound: {
    id: 'transfer-package-not-found',
    description: 'Package not found while trying to transfer',
    defaultMessage: 'Virheellinen urakka! Kokeile päivittää sivu.',
  },

  transferPackageNoAccess: {
    id: 'transfer-package-no-access',
    description: 'User does not have the right to transfer package',
    defaultMessage:
      'Sinulla ei ole oikeuksia siirtää urakkaa toiselle yritykselle!',
  },

  transferAlreadyExists: {
    id: 'transfer-already-exists',
    description: 'Transfer confirmation has already been sent',
    defaultMessage: 'Käyttäjälle on jo lähetetty siirtovahvistus!',
  },

  transferMailError: {
    id: 'transfer-mail-error',
    description: 'An error happened while sending transfer mail',
    defaultMessage:
      'Virhe lähetettäessä vahvistusta!' + ' Koita myöhemmin uudelleen.',
  },

  deleteRightsSuccess: {
    id: 'delete-rights-success',
    description: 'Deleted user rights successfully',
    defaultMessage: 'Käyttäjältä {name} poistettiin oikeudet!',
  },

  deleteRightsPackageNotFound: {
    id: 'delete-rights-package-not-found',
    description: 'Package not found while trying to delete rights',
    defaultMessage: 'Virheellinen urakka! Kokeile päivittää sivu.',
  },

  deleteRightsPackageNoAccess: {
    id: 'delete-rights-package-no-access',
    description: 'User does not have the right to delete rights',
    defaultMessage: 'Sinulla ei ole oikeuksia poistaa käyttäjien oikeuksia!',
  },

  deleteRightsNotFound: {
    id: 'delete-rights-not-found',
    description: 'Rights not found while trying to delete rights',
    defaultMessage: 'Käyttäjällä ei ole oikeuksia! Kokeile päivittää sivu.',
  },

  giveRightsSuccess: {
    id: 'give-rights-success',
    description: 'Gave user rights to a package successfully',
    defaultMessage: 'Käyttäjälle {name} annettiin oikeudet urakkaan {package}!',
  },

  giveApprovalRightSuccess: {
    id: 'give-approval-right-success',
    description: 'Successfully gave approval rights',
    defaultMessage:
      'Käyttäjälle {name} annettiin oikeus todeta ' + 'tuotekelpoisuuksia',
  },

  removeApprovalRightSuccess: {
    id: 'remove-approval-right-success',
    description: 'Successfully removed approval rights',
    defaultMessage:
      'Käyttäjältä {name} poistettiin oikeus todeta ' + 'tuotekelpoisuuksia',
  },

  giveRightsNotFound: {
    id: 'give-rights-not-found',
    description: 'Package not found while trying to give rights',
    defaultMessage: 'Urakkaa ei ole olemassa! Kokeile päivittää sivu.',
  },

  giveRightsNoAccess: {
    id: 'give-rights-package-no-access',
    description: 'User does not have the right to give rights',
    defaultMessage: 'Sinulla ei ole oikeuksia antaa oikeuksia!',
  },

  giveRightsAlreadyExists: {
    id: 'give-rights-already-exists',
    description: 'User already has rights when trying to give rights',
    defaultMessage: 'Käyttäjällä on jo oikeudet urakkaan!',
  },

  giveRightsInvalidUsername: {
    id: 'give-rights-invalid-username',
    description: 'Invalid username when trying to give rights',
    defaultMessage: 'Käyttäjää ei ole olemassa! Kokeile päivittää sivu.',
  },

  giveAllRightsSuccess: {
    id: 'give-all-rights-success',
    description: 'Gave user all rights successfully',
    defaultMessage: 'Käyttäjän {name} oikeuksia muokattiin!',
  },

  giveAllRightsNoAccess: {
    id: 'give-all-rights-no-access',
    description: 'User does not have the right to give all rights',
    defaultMessage: 'Sinulla ei ole oikeuksia antaa tätä oikeutta!',
  },

  giveAllRightsAlreadyExists: {
    id: 'give-all-rights-already-exists',
    description: 'User already has all rights when trying to give all rights',
    defaultMessage:
      'Käyttäjällä on jo THP pääkäyttäjä -oikeus! Kokeile päivittää sivu.',
  },

  giveAllRightsInvalidUsername: {
    id: 'give-all-rights-invalid-username',
    description: 'Invalid username when trying to give all rights',
    defaultMessage: 'Käyttäjää ei ole olemassa! Kokeile päivittää sivu.',
  },

  giveAllRightsInvalidUser: {
    id: 'give-all-rights-invalid-user',
    description: 'Invalid user when trying to give all rights',
    defaultMessage:
      'Et voi muuttaa tämän käyttäjän oikeuksia! Kokeile päivittää sivu.',
  },

  removeAllRightsSuccess: {
    id: 'remove-all-rights-success',
    description: 'Removed user all rights successfully',
    defaultMessage: 'Käyttäjältä {name} poistettiin oikeus!',
  },

  removeAllRightsNoAccess: {
    id: 'remove-all-rights-no-access',
    description: 'User does not have the right to remove all rights',
    defaultMessage: 'Sinulla ei ole oikeuksia poistaa käyttäjän oikeutta!',
  },

  removeAllRightsMissing: {
    id: 'remove-all-rights-missing',
    description:
      'User does not have all rights when trying to remove all rights',
    defaultMessage:
      'Käyttäjällä ei ole THP pääkäyttäjän oikeutta! Kokeile päivittää sivu.',
  },

  removeAllRightsInvalidUsername: {
    id: 'remove-all-rights-invalid-username',
    description: 'Invalid username when trying to remove all rights',
    defaultMessage: 'Käyttäjää ei ole olemassa! Kokeile päivittää sivu.',
  },

  removeAllRightsInvalidUser: {
    id: 'remove-all-rights-invalid-user',
    description: 'Invalid user when trying to remove all rights',
    defaultMessage:
      'Et voi muuttaa tämän käyttäjän oikeuksia! Kokeile päivittää sivu.',
  },

  generateReportSuccess: {
    id: 'generate-report-success',
    description: 'Started report generation successfully',
    defaultMessage: 'Urakan "{name}" yhteenvedon luominen aloitettiin!',
  },

  generateReportPackageNotFound: {
    id: 'generate-report-package-not-found',
    description: 'Package not found while trying to generate report',
    defaultMessage: 'Virheellinen urakka! Kokeile päivittää sivu.',
  },

  generateReportPackageNoAccess: {
    id: 'generate-report-package-no-access',
    description: 'User does not have the right to generate a report',
    defaultMessage:
      'Sinulla ei ole oikeuksia luoda yhteenvetoa tälle urakalle!',
  },

  messageQueueDown: {
    id: 'message-queue-down',
    description: 'Backend message queue failed',
    defaultMessage: 'Palvelimella tapahtui virhe. Yritä myöhemmin uudelleen.',
  },

  productExternalNotFound: {
    id: 'product-external-not-found',
    description: 'Product was not found from external source',
    defaultMessage: 'Hakemasi tuotteen tietoja ei valitettavasti löydy.',
  },

  uninviteSuccess: {
    id: 'package-uninvite-success',
    description: 'User was uninvited successfully',
    defaultMessage: 'Kutsu poistettu käyttäjältä {name}',
  },

  inviteNotFound: {
    id: 'package-invite-not-found',
    description: 'User is not invited in the package',
    defaultMessage: 'Kutsua ei ole olemassa. Kokeile päivittää sivu!',
  },

  packageImportFaulty: {
    id: 'package-import-faulty',
    description: 'Import file is faulty',
    defaultMessage: 'Tiedosto on viallinen! Tarkista muotoilu.',
  },

  packageImportPackageExists: {
    id: 'package-import-package-exists',
    description: 'Package defined in import already exists',
    defaultMessage:
      'Tiedostossa ollut urakka on jo urakassa, tai määritelty ' +
      'useampaan kertaan!',
  },

  packageImportProductExists: {
    id: 'package-import-product-exists',
    description: 'Product defined in import already exists',
    defaultMessage:
      'Tiedostossa ollut tuote on jo urakassa, tai määritelty ' +
      'useampaan kertaan!',
  },

  packageImportExternalProductNotFound: {
    id: 'package-import-external-product-not-found',
    description: 'External product defined in import was not found',
    defaultMessage: 'Tiedostossa ollutta tuotetta ei ole olemassa!',
  },

  packageImportProductNotFound: {
    id: 'package-import-product-not-found',
    description: 'Product defined in import was not found',
    defaultMessage:
      'Tiedostossa ollutta yritys- tai urakkakohtaista ' +
      'tuotetta ei ole olemassa!',
  },

  packageImportSuccess: {
    id: 'package-import-success',
    description: 'Import successful',
    defaultMessage: 'Urakkapohjan tuonti onnistui!',
  },

  packageImportStarted: {
    id: 'package-import-started',
    description: 'Import started',
    defaultMessage: 'Urakan tuonti meneillään, pieni hetki..',
  },

  phasesStarted: {
    id: 'phases-started',
    description: 'Phases creation started',
    defaultMessage: 'Luodaan rakennusvaiheita...',
  },

  phasesSuccess: {
    id: 'phases-success',
    description: 'Phases creation success',
    defaultMessage: 'Rakennusvaiheiden luonti onnistui',
  },

  phasesPartial: {
    id: 'phases-partial',
    description: 'Phases creation partial success',
    defaultMessage: 'Rakennusvaiheiden luonti onnistui vain osittain',
  },

  phasesFailed: {
    id: 'phases-failed',
    description: 'Phases creation failed',
    defaultMessage: 'Rakennusvaiheiden luonti epäonnistui',
  },

  approvePackageCarbonFootprintSuccess: {
    id: 'approve-package-carbon-footprint-success',
    description: 'Package carbon footprint was approved successfully',
    defaultMessage: 'Urakan "{name}" hiilijalanjälki todettu!',
  },

  disapprovePackageCarbonFootprintSuccess: {
    id: 'disapprove-package-carbon-footprint-success',
    description: 'Package carbon footprint was disapproved successfully',
    defaultMessage: 'Urakan "{name}" hiilijalanjäljen todennus peruttu!',
  },

  importFromFileStarted: {
    id: 'package-import-from-file-started',
    description: 'Import from file started',
    defaultMessage: 'Tuodaan tuotteita tiedostosta, pieni hetki..',
  },

  importFromFileSuccess: {
    id: 'package-import-from-file-success',
    description: 'Import from file success',
    defaultMessage: 'Tuotteiden tuonti onnistui',
  },

  importFromFileFailed: {
    id: 'package-import-from-filestarted',
    description: 'Import from file started',
    defaultMessage: 'Tuotteiden tuonti epäonnistui',
  },

  copyPackageProductSuccess: {
    id: 'copy-package-product-success',
    description: 'Product was copied successfully',
    defaultMessage: 'Tuote kopioitu!',
  },

  copyPackageProductFail: {
    id: 'copy-package-product-fail',
    description: 'Product could not be copied',
    defaultMessage: 'Tuotteen kopiointi epäonnistui!',
  },

  setPackageApproverSuccess: {
    id: 'set-package-approver-success',
    description: 'Package approver set successfully',
    defaultMessage: 'Tuotekelpoisuuden todentaja muutettu!',
  },
});

class PackageActionCreators extends CommonActionCreators {
  constructor(dispatcher, apiUtils) {
    super(dispatcher, apiUtils);
    this.apiBaseUrl = apiUtils._apiBaseUrl;
  }

  setAddToParent(id) {
    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGE_ADD_TO_PARENT,
      parent: id,
    });
  }

  setDownloadModalOpen() {
    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGE_OPEN_DOWNLOAD_MODAL,
    });
  }

  setMassApproveModalOpen(installMode) {
    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGE_OPEN_MASS_APPROVE_MODAL,
      installMode,
    });
    this._dispatcher.dispatch({
      type: ActionTypes.RESET_COUNTER,
    });
  }

  searchPackages(query) {
    if (!query) {
      this._dispatcher.dispatch({
        type: ActionTypes.PACKAGE_RECEIVE_SEARCH,
        packages: null,
      });

      return;
    }

    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGE_SEARCH_LOADING,
    });

    const escapedQuery = encodeURI(query);
    this._apiUtils
      .get(`/packages/search/${escapedQuery}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_SEARCH,
          packages: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_SEARCH_ERROR,
          error: error,
        });
      });
  }

  getPackages() {
    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGES_LOADING,
    });

    if (localStorage.getItem('recentlyViewedPackages')) {
      localStorage.setItem(
        'recentlyViewedPackagesToShow',
        localStorage.getItem('recentlyViewedPackages')
      );
    }

    this._apiUtils
      .get('/packages')
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_ALL,
          packages: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_ALL_ERROR,
          error: error,
        });
      });
  }

  getArchivedPackages() {
    this._apiUtils
      .get('/packages/archived')
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_ALL,
          packages: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_ALL_ERROR,
          error: error,
        });
      });
  }

  getArchivedCount() {
    this._apiUtils
      .get('/packages/archived/count')
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_ARCHIVED_COUNT,
          count: response.body.count,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_ARCHIVED_COUNT_ERROR,
          error: error,
        });
      });
  }

  getPackageTree(id) {
    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGES_LOADING,
    });

    this._apiUtils
      .get(`/packages/${id}/tree`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_ALL,
          packages: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_ALL_ERROR,
          error: error,
        });
      });
  }

  getPackage(id) {
    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGE_LOADING,
    });

    this._apiUtils
      .get(`/packages/${id}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE,
          package: response.body,
        });

        if (response.body.level === 1) {
          let recentlyViewedPackages = [];
          if (localStorage.getItem('recentlyViewedPackages')) {
            recentlyViewedPackages = JSON.parse(
              localStorage.getItem('recentlyViewedPackages')
            );
          }

          recentlyViewedPackages = recentlyViewedPackages.filter(
            (packageId) => Number(packageId) !== Number(id)
          );

          if (
            recentlyViewedPackages.length > 4 &&
            !recentlyViewedPackages.includes(id)
          ) {
            recentlyViewedPackages.pop();
          }

          recentlyViewedPackages.unshift(Number(id));

          localStorage.setItem(
            'recentlyViewedPackages',
            JSON.stringify(recentlyViewedPackages)
          );
        }
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.packageViewNoAccess);
            break;

          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchFutureMessage('danger', messages.packageViewNotFound);

            page.redirect('/');
            break;

          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PACKAGE_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  clearPackage() {
    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGE_CLEAR,
    });
  }

  cantModifyRTMainUser() {
    this._dispatchMessage('danger', messages.cantModifyRTMainUser);
  }

  setActivePackage(id) {
    this._dispatcher.dispatch({
      type: ActionTypes.SET_ACTIVE_PACKAGE,
      id,
    });
  }

  openMobileMenu() {
    this._dispatcher.dispatch({
      type: ActionTypes.OPEN_MOBILE_MENU,
    });
  }

  closeMobileMenu() {
    this._dispatcher.dispatch({
      type: ActionTypes.CLOSE_MOBILE_MENU,
    });
  }

  closeActivePackage(id) {
    this._dispatcher.dispatch({
      type: ActionTypes.CLOSE_ACTIVE_PACKAGE,
      id,
    });
  }

  getPackageProducts(id) {
    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGE_PRODUCTS_LOADING,
    });

    this._apiUtils
      .get(`/packages/${id}/products`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_PRODUCTS,
          pkg: id,
          products: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_PRODUCTS_ERROR,
          error: error,
        });
      });
  }

  getPackageProduct(packageID, product) {
    this._dispatcher.dispatch({
      type: ActionTypes.PRODUCT_LOADING,
    });

    this._apiUtils
      .get(`/packages/${packageID}/products/${product.id}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE,
          product: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PRODUCT_RECEIVE_ERROR,
              error: error,
            });

            this._dispatchMessage('danger', messages.productExternalNotFound);
            this._dispatcher.dispatch({
              type: ActionTypes.PRODUCT_RECEIVE_ERROR,
              error: error,
            });
        }
      });
  }

  addPackage(data) {
    this._apiUtils
      .post('/packages', data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_ADDED,
          data: response.body,
        });

        this._dispatchFutureMessage('success', messages.createPackageSuccess, {
          name: response.body.name,
          id: response.body.id,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_PARENT_NO_ACCESS:
            this._dispatchMessage('danger', messages.createPackageNoAccess);
            break;

          case ErrorTypes.ERROR_PACKAGE_NO_LICENSE:
            this._dispatchMessage('danger', messages.createPackageNoLicense);
            break;

          case ErrorTypes.ERROR_PACKAGE_PARENT_NOT_FOUND:
            this._dispatchMessage(
              'danger',
              messages.createPackageParentNotFound
            );
            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage('danger', messages.createPackageArchived);
            break;

          case ErrorTypes.ERROR_PACKAGE_EXISTS_ON_LEVEL:
            this._dispatchMessage(
              'danger',
              messages.createPackageExistsOnLevel
            );
            break;

          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PACKAGE_ADDED_ERROR,
              error: error,
            });
        }
      });
  }

  editPackage(data) {
    this._apiUtils
      .patch(`/packages/${data.id}`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_EDITED,
          data: response.body,
        });
        this._dispatchMessage('success', messages.editPackageSuccess);
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_PARENT_NOT_FOUND:
            this._dispatchMessage('danger', messages.editPackageParentNotFound);

            break;

          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchFutureMessage('danger', messages.editPackageNotFound);

            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.editPackageNoAccess);
            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage('danger', messages.editPackageArchived);
            break;

          case ErrorTypes.ERROR_SUBPACKAGES_NOT_COMPLETE:
            this._dispatchMessage('danger', messages.subpackagesNotComplete);
            break;

          case ErrorTypes.ERROR_FORBIDDEN_META_FIELD:
            this._dispatchMessage('danger', messages.editForbiddenMetaField);
            break;

          case ErrorTypes.ERROR_PACKAGE_EXISTS_ON_LEVEL:
            this._dispatchMessage(
              'danger',
              messages.createPackageExistsOnLevel
            );
            break;

          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PACKAGE_EDITED_ERROR,
              error: error,
            });
        }
      });
  }

  approvePackageCarbonFootprint(id, gwpApproved) {
    const data = {
      id,
      gwpApproved,
    };

    this._apiUtils
      .patch(`/packages/${data.id}/approve-carbon-footprint`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_EDITED,
          data: response.body,
        });

        if (gwpApproved) {
          this._dispatchMessage(
            'success',
            messages.approvePackageCarbonFootprintSuccess,
            { name: response.body.name }
          );
        } else {
          this._dispatchMessage(
            'success',
            messages.disapprovePackageCarbonFootprintSuccess,
            { name: response.body.name }
          );
        }
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_PARENT_NOT_FOUND:
            this._dispatchMessage(
              'danger',
              messages.completePackageParentNotFound
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchFutureMessage(
              'danger',
              messages.completePackageNotFound
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.completePackageNoAccess);
            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage('danger', messages.completePackageArchived);
            break;

          case ErrorTypes.ERROR_SUBPACKAGES_NOT_COMPLETE:
            this._dispatchMessage('danger', messages.subpackagesNotComplete);
            break;

          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PACKAGE_EDITED_ERROR,
              error: error,
            });
        }
      });
  }

  completePackage(data) {
    this._apiUtils
      .post(`/packages/${data.id}/complete`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_EDITED,
          data: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_PARENT_NOT_FOUND:
            this._dispatchMessage(
              'danger',
              messages.completePackageParentNotFound
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchFutureMessage(
              'danger',
              messages.completePackageNotFound
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.completePackageNoAccess);
            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage('danger', messages.completePackageArchived);
            break;

          case ErrorTypes.ERROR_SUBPACKAGES_NOT_COMPLETE:
            this._dispatchMessage('danger', messages.subpackagesNotComplete);
            break;

          default:
            this._dispatcher.dispatch({
              type: ActionTypes.PACKAGE_EDITED_ERROR,
              error: error,
            });
        }
      });
  }

  removePackage(id) {
    return this._apiUtils
      .delete(`/packages/${id}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_REMOVED,
          package: response.body,
        });

        this._dispatchMessage('success', messages.packageRemoveSuccess, {
          name: response.body.name,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchFutureMessage(
              'danger',
              messages.packageRemoveNotFound
            );

            page.redirect('/');
            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.packageRemoveNoAccess);
            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage('danger', messages.packageRemoveArchived);
            break;

          case ErrorTypes.ERROR_PACKAGE_NOT_EMPTY:
            this._dispatchMessage('danger', messages.packageRemoveNotEmpty);
            break;

          default:
            console.error(error);
        }
      });
  }

  archivePackage(id) {
    this._apiUtils
      .post(`/packages/${id}/archive`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_ARCHIVED,
          data: response.body,
        });

        this._dispatchMessage('success', messages.packageArchiveSuccess, {
          name: response.body.name,
        });

        page.redirect('/');
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchFutureMessage(
              'danger',
              messages.packageArchiveNotFound
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_ARCHIVE_NO_ACCESS:
            this._dispatchMessage('danger', messages.packageArchiveNoAccess);
            break;

          case ErrorTypes.ERROR_PACKAGE_NOT_ROOT:
            this._dispatchMessage('danger', messages.packageArchiveNotRoot);
            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage('danger', messages.packageArchiveIsArchived);

            break;

          default:
            console.error(error);
        }
      });
  }

  unarchivePackage(id) {
    this._apiUtils
      .post(`/packages/${id}/unarchive`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_UNARCHIVED,
          data: response.body,
        });

        this._dispatchMessage('success', messages.packageUnarchiveSuccess, {
          name: response.body.name,
        });

        this.getArchivedCount();
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchFutureMessage(
              'danger',
              messages.packageUnarchiveNotFound
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.packageUnarchiveNoAccess);

            break;

          case ErrorTypes.ERROR_PACKAGE_ARCHIVE_NO_ACCESS:
            this._dispatchMessage('danger', messages.packageArchiveNoAccess);
            break;

          case ErrorTypes.ERROR_PACKAGE_NOT_ROOT:
            this._dispatchMessage('danger', messages.packageUnarchiveNotRoot);

            break;

          case ErrorTypes.ERROR_PACKAGE_NOT_ARCHIVED:
            this._dispatchMessage(
              'danger',
              messages.packageUnarchiveNotArchived
            );

            break;

          default:
            console.error(error);
        }
      });
  }

  addPackageProduct(packageID, product) {
    const data = {
      package: packageID,
      product: product.data.id,
    };

    const uri = product.data.manual
      ? `/packages/${packageID}/products/addManual/${product.data.id}`
      : `/packages/${packageID}/products`;

    return this._apiUtils
      .post(uri, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_ADDED_PRODUCT,
          product: response.body,
        });

        // Refreshes the information panel when adding to plan from search
        this._dispatcher.dispatch({
          type: ActionTypes.PRODUCT_RECEIVE,
          product: response.body,
        });

        this._dispatchMessage('success', messages.addProductSuccess, {
          name: response.body.data.name,
        });

        return response.body;
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchMessage('danger', messages.addProductPackageNotFound);

            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.addProductPackageNoAccess);

            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage('danger', messages.addProductPackageArchived);

            break;

          case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
            this._dispatchMessage(
              'danger',
              messages.addProductPackageCompleted
            );

            break;

          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchMessage('danger', messages.productNotFound);
            break;

          case ErrorTypes.ERROR_PRODUCT_ALREADY_EXISTS:
            this._dispatchMessage('danger', messages.productAlreadyExists);
            break;

          default:
            console.error(error);
        }
      });
  }

  async addManualProductToPackage(packageId, data) {
    try {
      const res = await this._apiUtils.post(
        `/packages/${packageId}/products/addManual`,
        data
      );

      this._dispatcher.dispatch({
        type: ActionTypes.PACKAGE_ADDED_PRODUCT,
        product: res.body,
      });

      // Refreshes the information panel when adding to plan from search
      this._dispatcher.dispatch({
        type: ActionTypes.PRODUCT_RECEIVE,
        product: res.body,
      });

      this._dispatchMessage('success', messages.addProductSuccess, {
        name: res.body.data.name,
      });

      return res.body;
    } catch (error) {
      if (this._isAuthorizationError(error)) {
        return;
      }

      switch (error.message) {
        case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
          this._dispatchMessage('danger', messages.addProductPackageNotFound);

          break;

        case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
          this._dispatchMessage('danger', messages.addProductPackageNoAccess);

          break;

        case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
          this._dispatchMessage('danger', messages.addProductPackageArchived);

          break;

        case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
          this._dispatchMessage('danger', messages.addProductPackageCompleted);

          break;

        case ErrorTypes.ERROR_PRODUCT_NAME_EMPTY:
          this._dispatchMessage('danger', messages.productNameEmpty);
          break;

        case ErrorTypes.ERROR_PRODUCT_MANUFACTURER_EMPTY:
          this._dispatchMessage('danger', messages.productManufacturerEmpty);

          break;

        default:
          console.error(error);
      }
    }
  }

  toggleExpanded(packageID) {
    this._dispatcher.dispatch({
      type: ActionTypes.TOGGLE_EXPANDED,
      packageId: packageID,
    });
  }

  async instaddProduct(packageID, product) {
    const data = {
      package: packageID,
      product: product.data.id,
    };

    const uri = product.data.manual
      ? `/packages/${packageID}/products/addManual/${product.data.id}`
      : `/packages/${packageID}/products`;

    try {
      const postRes = await this._apiUtils.post(uri, data);

      this._dispatcher.dispatch({
        type: ActionTypes.PACKAGE_ADDED_PRODUCT,
        product: postRes.body,
      });

      const patchRes = await this._apiUtils.patch(
        `/products/${postRes.body.id}`,
        {
          id: postRes.body.id,
          status: 1,
        }
      );

      this._dispatcher.dispatch({
        type: ActionTypes.PRODUCT_INSTALLED,
        product: patchRes.body,
      });

      // Refreshes the information panel when adding to plan from search
      this._dispatcher.dispatch({
        type: ActionTypes.PRODUCT_RECEIVE,
        product: patchRes.body,
      });

      this._dispatchMessage('success', messages.instaddProductSuccess, {
        name: patchRes.body.data.name,
      });

      return patchRes.body;
    } catch (error) {
      if (this._isAuthorizationError(error)) {
        return;
      }

      switch (error.message) {
        case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
          this._dispatchMessage('danger', messages.addProductPackageNotFound);

          break;

        case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
          this._dispatchMessage('danger', messages.addProductPackageNoAccess);

          break;

        case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
          this._dispatchMessage('danger', messages.addProductPackageArchived);

          break;

        case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
          this._dispatchMessage('danger', messages.addProductPackageCompleted);

          break;

        case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
          this._dispatchMessage('danger', messages.productNotFound);
          break;

        case ErrorTypes.ERROR_PRODUCT_ALREADY_EXISTS:
          this._dispatchMessage('danger', messages.productAlreadyExists);
          break;

        default:
          console.error(error);
      }
    }
  }

  // Is this actually used anywhere?
  getUpdatedProducts(packageID) {
    if (packageID === null) {
      this._dispatcher.dispatch({
        type: ActionTypes.PACKAGE_RECEIVE_PRODUCTS,
        products: null,
      });

      return;
    }

    this._apiUtils
      .get(`/packages/${packageID}/products/updates`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_PRODUCTS,
          products: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_PRODUCTS_ERROR,
          error: error,
        });
      });
  }

  giveRights(packageID, username) {
    const data = {
      id: packageID,
      username: username,
    };

    this._apiUtils
      .post(`/packages/${packageID}/rights`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.COMPANY_RIGHTS_ADDED,
          data: response.body,
        });

        this._dispatchMessage('success', messages.giveRightsSuccess, {
          name: response.body.username,
          package: response.body.package.name,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchMessage('danger', messages.giveRightsNotFound);
            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.giveRightsNoAccess);
            break;

          case ErrorTypes.ERROR_RIGHTS_GIVE_ALREADY_EXISTS:
            this._dispatchMessage('danger', messages.giveRightsAlreadyExists);
            break;

          case ErrorTypes.ERROR_RIGHTS_GIVE_INVALID_USERNAME:
            this._dispatchMessage('danger', messages.giveRightsInvalidUsername);
            break;

          default:
            console.error(error);
        }
      });
  }

  giveApprovalRight(packageID, username) {
    const data = {
      id: packageID,
      username,
      status: true,
    };

    this._apiUtils
      .patch(`/packages/${packageID}/rights/approval`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_UPDATE_RIGHTS,
          rights: response.body.rights,
        });

        this._dispatchMessage('success', messages.giveApprovalRightSuccess, {
          name: response.body.username,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchMessage('danger', messages.giveRightsNotFound);
            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.giveRightsNoAccess);
            break;

          case ErrorTypes.ERROR_RIGHTS_GIVE_INVALID_USERNAME:
            this._dispatchMessage('danger', messages.giveRightsInvalidUsername);
            break;

          case ErrorTypes.ERROR_RIGHTS_GIVE_NO_ACCESS:
            this._dispatchMessage('danger', messages.giveRightsNoAccess);
            break;

          default:
            console.error(error);
        }
      });
  }

  removeApprovalRight(packageID, username) {
    const data = {
      id: packageID,
      username,
      status: false,
    };

    this._apiUtils
      .patch(`/packages/${packageID}/rights/approval`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_UPDATE_RIGHTS,
          rights: response.body.rights,
        });

        this._dispatchMessage('warning', messages.removeApprovalRightSuccess, {
          name: response.body.username,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchMessage('danger', messages.giveRightsNotFound);
            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.giveRightsNoAccess);
            break;

          case ErrorTypes.ERROR_RIGHTS_GIVE_INVALID_USERNAME:
            this._dispatchMessage('danger', messages.giveRightsInvalidUsername);
            break;

          case ErrorTypes.ERROR_RIGHTS_GIVE_NO_ACCESS:
            this._dispatchMessage('danger', messages.giveRightsNoAccess);
            break;

          default:
            console.error(error);
        }
      });
  }

  giveAllRights(username, role) {
    const data = {
      username: username,
      role,
    };

    const escapedUsername = encodeURI(username);
    this._apiUtils
      .patch(`/users/${escapedUsername}`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.COMPANY_ALL_RIGHTS_ADDED,
          data: response.body,
        });

        this._dispatchMessage('success', messages.giveAllRightsSuccess, {
          name: response.body.username,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_RIGHTS_GIVE_ALL_NO_ACCESS:
            this._dispatchMessage('danger', messages.giveAllRightsNoAccess);
            break;

          case ErrorTypes.ERROR_RIGHTS_GIVE_ALL_ALREADY_EXISTS:
            this._dispatchMessage(
              'danger',
              messages.giveAllRightsAlreadyExists
            );
            break;

          case ErrorTypes.ERROR_RIGHTS_GIVE_ALL_INVALID_USERNAME:
            this._dispatchMessage(
              'danger',
              messages.giveAllRightsInvalidUsername
            );

            break;

          case ErrorTypes.ERROR_RIGHTS_GIVE_INVALID_USER:
            this._dispatchMessage('danger', messages.giveAllRightsInvalidUser);
            break;

          default:
            console.error(error);
        }
      });
  }

  getChemicalChartInfo(packageID) {
    this._apiUtils
      .get(`/packages/${packageID}/chemicalchart/info`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_CHEMICAL_CHART_INFO,
          data: response.body,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getChemicalChartData(uuid) {
    this._dispatcher.dispatch({
      type: ActionTypes.CHEMICAL_CHART_DATA_LOADING,
    });
    this._apiUtils
      .get(`/chemicalchart/${uuid}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.RECEIVE_CHEMICAL_CHART_DATA,
          data: response.body,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  createPublicChemicalChart(packageID) {
    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGE_CREATE_CHEMICAL_CHART_LOADING,
    });
    this._apiUtils
      .post(`/packages/${packageID}/chemicalchart`, {})
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_CHEMICAL_CHART_INFO,
          data: response.body,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  downloadChemicalChartCode(uuid) {
    const uri = `${this.apiBaseUrl}/chemicalchart/${uuid}/code`;
    window.location.assign(uri);
  }

  removeChemicalChart(packageID) {
    this._apiUtils
      .delete(`/packages/${packageID}/chemicalchart`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_CHEMICAL_CHART_INFO,
          data: response.body,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  removeAllRights(username) {
    const data = {
      username: username,
    };

    const escapedUsername = encodeURI(username);
    this._apiUtils
      .patch(`/users/${escapedUsername}`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.COMPANY_ALL_RIGHTS_REMOVED,
          data: response.body,
        });

        this._dispatchMessage('success', messages.removeAllRightsSuccess, {
          name: response.body.username,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_RIGHTS_REMOVE_ALL_NO_ACCESS:
            this._dispatchMessage('danger', messages.removeAllRightsNoAccess);
            break;

          case ErrorTypes.ERROR_RIGHTS_REMOVE_ALL_MISSING:
            this._dispatchMessage('danger', messages.removeAllRightsMissing);

            break;

          case ErrorTypes.ERROR_RIGHTS_REMOVE_ALL_INVALID_USERNAME:
            this._dispatchMessage(
              'danger',
              messages.removeAllRightsInvalidUsername
            );

            break;

          case ErrorTypes.ERROR_RIGHTS_GIVE_INVALID_USER:
            this._dispatchMessage(
              'danger',
              messages.removeAllRightsInvalidUser
            );
            break;

          default:
            console.error(error);
        }
      });
  }

  inviteUser(packageID, username, canApprove) {
    const data = {
      id: packageID,
      username: username,
      can_approve: canApprove,
    };

    this._apiUtils
      .post(`/packages/${packageID}/invite`, data)
      .then((response) => {
        this._dispatchMessage('success', messages.invitationSuccess, {
          name: username,
        });

        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_INVITED,
          data: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchMessage('danger', messages.invitePackageNotFound);
            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.invitePackageNoAccess);
            break;

          case ErrorTypes.ERROR_INVITE_ALREADY_HAS_RIGHTS:
            this._dispatchMessage('danger', messages.inviteAlreadyHasRights);
            break;

          case ErrorTypes.ERROR_INVITE_ALREADY_EXISTS:
            this._dispatchMessage('danger', messages.inviteAlreadyExists);
            break;

          case ErrorTypes.ERROR_INVITE_MAIL_ERROR:
            this._dispatchMessage('danger', messages.inviteMailError);
            break;

          case ErrorTypes.ERROR_RIGHTS_GIVE_APPROVE_RIGHTS_NO_ACCESS:
            this._dispatchMessage('danger', messages.giveApproveRightsNoAccess);
            break;

          default:
            console.error(error);
        }
      });
  }

  deleteInvite(packageID, inviteID) {
    this._apiUtils
      .delete(`/packages/${packageID}/invite/${inviteID}`)
      .then((response) => {
        this._dispatchMessage('warning', messages.uninviteSuccess, {
          name: response.body.invite.email,
        });

        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_UNINVITED,
          data: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchMessage('danger', messages.invitePackageNotFound);

            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.invitePackageNoAccess);

            break;

          case ErrorTypes.ERROR_INVITE_NOT_FOUND:
            this._dispatchMessage('danger', messages.inviteNotFound);

            break;

          default:
            console.log(error);
        }
      });
  }

  transferPackage(packageID, username) {
    const data = {
      id: packageID,
      username: username,
    };

    this._apiUtils
      .post(`/packages/${packageID}/transfer`, data)
      .then((response) => {
        this._dispatchMessage('success', messages.transferSuccess, {
          name: response.body.username,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchMessage('danger', messages.transferPackageNotFound);

            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.transferPackageNoAccess);

            break;

          case ErrorTypes.ERROR_TRANSFER_ALREADY_EXISTS:
            this._dispatchMessage('danger', messages.transferAlreadyExists);
            break;

          case ErrorTypes.ERROR_TRANSFER_MAIL_ERROR:
            this._dispatchMessage('danger', messages.transferMailError);
            break;

          default:
            console.error(error);
        }
      });
  }

  getRights(id) {
    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGE_RIGHTS_LOADING,
    });

    this._apiUtils
      .get(`/packages/${id}/rights`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_RIGHTS,
          data: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_RIGHTS_ERROR,
          error: error,
        });
      });
  }

  downloadRightsReport(pkg) {
    this._apiUtils
      .getBinary(`/packages/${pkg.id}/rights/download`)
      .then((response) => {
        this._downloadAsFile(
          `${pkg.name}-oikeudet.xlsx`,
          response.body,
          response.headers['content-type']
        );
      });
  }

  deleteRights(id, username) {
    const escapedUsername = encodeURI(username);
    this._apiUtils
      .delete(`/packages/${id}/rights/${escapedUsername}`)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_REMOVED_RIGHTS,
          rights: response.body,
        });

        this._dispatchMessage('success', messages.deleteRightsSuccess, {
          name: response.body.username,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchMessage(
              'danger',
              messages.deleteRightsPackageNotFound
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage(
              'danger',
              messages.deleteRightsPackageNoAccess
            );

            break;

          case ErrorTypes.ERROR_RIGHTS_NOT_FOUND:
            this._dispatchMessage('danger', messages.deleteRightsNotFound);
            break;

          default:
            console.error(error);
        }
      });
  }

  waitForReport(id, previousReport, tryCount) {
    if (tryCount >= 180) {
      this._dispatcher.dispatch({
        type: ActionTypes.PACKAGE_SUMMARY_RECEIVE_ERROR,
        error: new Error('Too many tries'),
        packageId: id,
      });
      return;
    }

    setTimeout(() => {
      this._apiUtils
        .get(`/packages/${id}`)
        .then((response) => {
          if (
            (!previousReport && response.body.summary) ||
            (previousReport &&
              response.body.summary &&
              previousReport.last_modified !==
                response.body.summary.last_modified)
          ) {
            this._dispatcher.dispatch({
              type: ActionTypes.PACKAGE_RECEIVE,
              package: response.body,
            });

            this._dispatcher.dispatch({
              type: ActionTypes.PACKAGE_SUMMARY_RECEIVED,
              packageId: id,
            });
          } else {
            this.waitForReport(id, previousReport, tryCount + 1);
          }
        })
        .catch((error) => {
          if (this._isAuthorizationError(error)) return;

          switch (error.message) {
            case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
              this._dispatchMessage('danger', messages.packageViewNoAccess);
              break;

            case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
              this._dispatchFutureMessage(
                'danger',
                messages.packageViewNotFound
              );
              break;

            default:
              break;
          }

          this._dispatcher.dispatch({
            type: ActionTypes.PACKAGE_SUMMARY_RECEIVE_ERROR,
            error: error,
            packageId: id,
          });
        });
    }, 5000);
  }

  generateReport(id, previousReport) {
    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGE_SUMMARY_LOADING,
      packageId: id,
    });

    const data = {
      id: id,
    };

    this._apiUtils
      .post(`/packages/${id}/report`, data)
      .then((response) => {
        this._dispatchMessage('success', messages.generateReportSuccess, {
          name: response.body.name,
          email: response.body.email,
        });

        this.waitForReport(id, previousReport, 1);
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchMessage(
              'danger',
              messages.generateReportPackageNotFound
            );

            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage(
              'danger',
              messages.generateReportPackageNoAccess
            );

            break;

          case ErrorTypes.ERROR_MESSAGE_QUEUE_DOWN:
            this._dispatchMessage('danger', messages.messageQueueDown);
            break;

          default:
            console.error(error);
        }
      });
  }

  downloadApprovalExcel(pkg) {
    this._dispatcher.dispatch({
      type: ActionTypes.PACKAGE_APPROVAL_REPORT_LOADING,
      data: true,
    });

    this._apiUtils
      .getBinary(`/packages/${pkg.id}/approval`)
      .then((response) => {
        // We need to convert to Blob to workaround a downloadjs bug
        // https://github.com/rndme/download/issues/56#issuecomment-348623136
        this._downloadAsFile(
          'tuotekelpoisuusyhteenveto-' + pkg.name + '.xlsx',
          response.body,
          response.headers['content-type']
        );

        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_APPROVAL_REPORT_LOADING,
          data: false,
        });
      })
      .catch((error) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_APPROVAL_REPORT_LOADING,
          data: false,
        });

        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchMessage('danger', messages.packageViewNotFound);

            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.packageViewNoAccess);

            break;

          default:
            console.error(error);
        }
      });
  }

  exportPackage(pkg) {
    this._apiUtils
      .get(`/packages/${pkg.id}/export`)
      .then((response) => {
        // We need to convert to Blob to workaround a downloadjs bug
        // https://github.com/rndme/download/issues/56#issuecomment-348623136
        this._downloadAsFile(
          'urakkapohja-' + pkg.name + '.txt',
          new Blob([response.body], { type: 'text/plain' }),
          'text/plain'
        );
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchMessage('danger', messages.packageViewNotFound);

            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.packageViewNoAccess);

            break;

          default:
            console.error(error);
        }
      });
  }

  createConstructionPhases(root, csvs) {
    this._dispatchMessage('warning', messages.phasesStarted);

    const failed = [];

    const promises = Object.entries(csvs).map((pair) => {
      const id = pair[0];
      const csv = pair[1];
      const data = new FormData();
      data.append('csv', new Blob(csv.split()));
      data.append('includeProducts', false);

      return this._apiUtils
        .post(`/packages/${id}/import`, data)
        .catch((error) => {
          failed.push({ id, csv, error });
        });
    });

    Promise.all(promises)
      .then(() => {
        this._dispatcher.dispatch({
          type: ActionTypes.MESSAGES_CLEAR,
        });

        // TODO: maybe have some nicer error handling in the future?
        // though, this project is doomed already and I've lost my sanity :)
        if (failed.length > 0) {
          if (failed.length === Object.keys(csvs).length) {
            this._dispatchMessage('danger', messages.phasesFailed);
          } else {
            this._dispatchMessage('warning', messages.phasesPartial);
          }
        } else {
          this._dispatchMessage('success', messages.phasesSuccess);
        }

        return this._apiUtils.get(`/packages/${root}/tree`);
      })
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_ALL,
          packages: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE_ALL_ERROR,
          error: error,
        });
      });
  }

  validateImportProducts(id, file) {
    const data = new FormData();
    data.append('xlsx', file);

    this._dispatcher.dispatch({
      type: ActionTypes.VALIDATE_IMPORT_PRODUCTS_LOADING,
    });

    this._apiUtils
      .post(`/packages/${id}/import-products/validate`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.RECEIVE_VALIDATE_IMPORT_PRODUCTS,
          products: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        this._dispatcher.dispatch({
          type: ActionTypes.RECEIVE_VALIDATE_IMPORT_PRODUCTS,
          products: [],
        });
      });
  }

  importSheetProducts(packageId, products) {
    const data = {
      products,
    };

    this._dispatcher.dispatch({
      type: ActionTypes.IMPORT_PRODUCTS_LOADING,
    });

    this._dispatchMessage('warning', messages.importFromFileStarted);

    return this._apiUtils
      .post(`/packages/${packageId}/import-products`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.RECEIVE_IMPORT_PRODUCTS,
          products: response.body,
        });

        this._dispatchMessage('success', messages.importFromFileSuccess);

        for (const prod of response.body.products) {
          if (packageId !== -1) {
            this._dispatcher.dispatch({
              type: ActionTypes.PACKAGE_ADDED_PRODUCT,
              product: prod,
            });
          } else {
            this._dispatcher.dispatch({
              type: ActionTypes.PRODUCT_MANUAL_ADDED,
              product: prod,
            });
          }
        }

        return response.body;
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        this._dispatchMessage('danger', messages.importFromFileFailed);
        console.error(error);
      });
  }

  clearImportProducts() {
    this._dispatcher.dispatch({
      type: ActionTypes.RECEIVE_VALIDATE_IMPORT_PRODUCTS,
      products: null,
    });
  }

  importPackage(id, file, includeProducts) {
    const data = new FormData();
    data.append('csv', file);
    data.append('includeProducts', includeProducts);

    this._dispatchMessage('warning', messages.packageImportStarted);

    this._apiUtils
      .post(`/packages/${id}/import`, data)
      .then((response) => {
        this._dispatchMessage('success', messages.packageImportSuccess);

        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_RECEIVE,
          package: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        // NOTE/TODO: these errors could be more in-depth
        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchMessage('danger', messages.packageViewNotFound);

            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.packageViewNoAccess);

            break;

          case ErrorTypes.ERROR_FILE_PARSE_FAILED:
            this._dispatchMessage('danger', messages.packageImportFaulty);

            break;

          case ErrorTypes.ERROR_PACKAGE_NAME_MISSING:
            this._dispatchMessage('danger', messages.packageImportFaulty);

            break;

          case ErrorTypes.ERROR_PACKAGE_PARENT_NOT_DEFINED_YET:
            this._dispatchMessage('danger', messages.packageImportFaulty);

            break;

          case ErrorTypes.ERROR_PACKAGE_EXISTS_ON_LEVEL:
            this._dispatchMessage(
              'danger',
              messages.packageImportPackageExists
            );

            break;

          case ErrorTypes.ERROR_PRODUCT_EXTERNAL_NOT_FOUND:
            this._dispatchMessage(
              'danger',
              messages.packageImportExternalProductNotFound
            );

            break;

          case ErrorTypes.ERROR_PRODUCT_NOT_FOUND:
            this._dispatchMessage(
              'danger',
              messages.packageImportProductNotFound
            );

            break;

          case ErrorTypes.ERROR_PRODUCT_ALREADY_EXISTS:
            this._dispatchMessage(
              'danger',
              messages.packageImportProductExists
            );

            break;

          default:
            console.error(error);
        }
      });
  }

  movePackage(packageID, targetID) {
    const data = {
      target: targetID,
    };

    return this._apiUtils
      .post(`/packages/${packageID}/move`, data)
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.PACKAGE_MOVED,
          package: response.body,
          from: packageID,
          to: targetID,
        });
        return response.body;
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        console.error(error);
      });
  }

  async movePackageProduct(productID, packageID, targetID) {
    const data = {
      target: targetID,
    };

    try {
      const res = await this._apiUtils.post(
        `/packages/products/${productID}/move`,
        data
      );

      // Refetch packages and their products after move
      this.getPackage(packageID);
      this.getPackage(targetID);
      this.getPackageProducts(packageID);
      this.getPackageProducts(targetID);

      return res.body;
    } catch (error) {
      if (!this._isAuthorizationError(error)) {
        console.error(error);
      }
    }
  }

  async copyPackageProduct(targetID, productID) {
    const data = {
      target: targetID,
    };

    try {
      const res = await this._apiUtils.post(
        `/packages/products/${productID}/copy`,
        data
      );

      this.getPackage(targetID);
      this.getPackageProducts(targetID);

      return res.body;
    } catch (error) {
      if (!this._isAuthorizationError(error)) {
        this._dispatchMessage('danger', messages.copyPackageProductFail);
        console.error(error);
      }
    }
  }

  setPackageApprover(packageId, username, email) {
    return this._apiUtils
      .patch(`/packages/${packageId}/approver`, { username, email })
      .then((response) => {
        this._dispatchMessage('success', messages.setPackageApproverSuccess);
        return response.body;
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchMessage('danger', messages.packageViewNotFound);
            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.packageViewNoAccess);
            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage('danger', messages.createPackageArchived);
            break;

          case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
            this._dispatchMessage(
              'danger',
              messages.addProductPackageCompleted
            );
            break;

          default:
            console.error(error);
        }
      });
  }

  getPackageApprover(packageId) {
    return this._apiUtils
      .get(`/packages/${packageId}/approver`)
      .then((response) => {
        return response.body;
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;
        switch (error.message) {
          case ErrorTypes.ERROR_PACKAGE_NOT_FOUND:
            this._dispatchMessage('danger', messages.packageViewNotFound);
            break;

          case ErrorTypes.ERROR_PACKAGE_NO_ACCESS:
            this._dispatchMessage('danger', messages.packageViewNoAccess);
            break;

          case ErrorTypes.ERROR_PACKAGE_IS_ARCHIVED:
            this._dispatchMessage('danger', messages.createPackageArchived);
            break;

          case ErrorTypes.ERROR_PACKAGE_IS_COMPLETED:
            this._dispatchMessage(
              'danger',
              messages.addProductPackageCompleted
            );
            break;

          default:
            console.error(error);
        }
      });
  }

  expandSubpackages(packageId) {
    this._dispatcher.dispatch({
      type: ActionTypes.EXPAND_SUBPACKAGES,
      package: packageId,
    });
  }

  collapseSubpackages(packageId) {
    this._dispatcher.dispatch({
      type: ActionTypes.COLLAPSE_SUBPACKAGES,
      package: packageId,
    });
  }

  getReportData(params) {
    this._dispatcher.dispatch({
      type: ActionTypes.REPORT_DATA_LOADING,
    });

    this._apiUtils
      .get(
        `/report?search=${params.search}&page=${params.page}&svhc=${params.svhc}`
      )
      .then((response) => {
        this._dispatcher.dispatch({
          type: ActionTypes.REPORT_DATA_RECEIVE,
          result: response.body,
        });
      })
      .catch((error) => {
        if (this._isAuthorizationError(error)) return;

        switch (error.message) {
          default:
            this._dispatcher.dispatch({
              type: ActionTypes.REPORT_DATA_ERROR,
              error: error,
            });
        }
      });
  }
}

export default PackageActionCreators;
