import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import AuthenticatedView from '../AuthenticatedView/AuthenticatedView';
import BarcodeScanner from '../BarcodeScanner/BarcodeScanner';
import FileRows from './FileRows';
import FileTimestamps from './FileTimestamps';
import Pagination from '../Pagination/Pagination';

const useStore = (store, setter) => {
  useEffect(() => {
    const subscription = store.addListener(() => {
      setter({ ...store.getState() });
    });
    return () => subscription.remove();
  }, [store, setter]);
};

function getCodeDescriptions(codes, dataCodes, defaultCodes) {
  const descriptions = [];

  if (!codes) return [];

  for (const code of codes.split(',')) {
    if (code === '-') {
      descriptions.push(code);
    } else {
      const match = dataCodes?.find((co) => co.value === code);
      if (match) {
        descriptions.push(match.label);
      } else {
        const defaultMatch = defaultCodes?.find((co) => co.value === code);
        if (defaultMatch) {
          descriptions.push(defaultMatch.label);
        }
      }
    }
  }

  return descriptions;
}

function WarningCodes({ codes }) {
  return (
    <>
      {codes.map((code) =>
        code !== '-' ? (
          <div key={code} className='warning-sign'>
            <img src={`/images/warningsigns/${code.substring(0, 5)}.jpg`} />
            <p>{code}</p>
          </div>
        ) : (
          <div>-</div>
        )
      )}
    </>
  );
}

function CodeRows({ codes }) {
  return (
    <>
      {codes.map((code, idx) => (
        <div
          key={code}
          style={{
            marginTop: idx > 0 ? '1rem' : '0',
          }}
        >
          {code}
        </div>
      ))}
    </>
  );
}

function ReportsView(props) {
  const [rtState, setRtState] = useState(props.rtStore.getState());
  const [packageState, setPackageState] = useState(
    props.packageStore.getState()
  );
  const [productState, setProductState] = useState(
    props.productStore.getState()
  );

  const [search, setSearch] = useState('');
  const [svhc, setSvhc] = useState(false);
  const [page, setPage] = useState(1);
  const [scanning, setScanning] = useState(false);

  useStore(props.rtStore, setRtState);
  useStore(props.packageStore, setPackageState);
  useStore(props.productStore, setProductState);

  const products = packageState.reportData?.products ?? [];
  const total =
    packageState.reportData?.products?.length > 0
      ? Number(packageState.reportData.products[0].total)
      : 0;

  return (
    <div className='ReportsView'>
      <Header
        user={rtState.user}
        packages={packageState.packageSearch}
        searchLoading={packageState.packageSearchLoading}
        packageActionCreators={props.packageActionCreators}
        rtActionCreators={props.rtActionCreators}
      />

      <div className='container-fluid content'>
        <h2>Projektien tuotteet</h2>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            setPage(1);
            props.packageActionCreators.getReportData({
              search,
              page: 1,
              svhc,
            });
          }}
        >
          <fieldset className='form-group field-center'>
            <input
              type='text'
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              className='searchfield'
              placeholder={
                'Syötä tuotteen nimi, urakka, GTIN-koodi, tuotekoodi tai vaaralauseke'
              }
            />
            <button
              type='button'
              className='btn btn-outline-secondary scan-button'
              onClick={() => setScanning(true)}
            >
              <i className='fa fa-barcode'></i>
            </button>
            {scanning ? (
              <BarcodeScanner
                setResult={(res) => {
                  setSearch(res);
                  setPage(1);
                  setScanning(false);
                  props.packageActionCreators.getReportData({
                    search: res,
                    page: 1,
                    svhc,
                  });
                }}
              />
            ) : null}
          </fieldset>
        </form>

        <div className='checkbox'>
          <label>
            <input
              type='checkbox'
              checked={svhc}
              disabled={packageState.reportDataLoading}
              onChange={(e) => {
                setSvhc(e.target.checked);
                setPage(1);
                props.packageActionCreators.getReportData({
                  search,
                  page: 1,
                  svhc: e.target.checked,
                });
              }}
            />
            <p>Erityistä huolta aiheuttava</p>
          </label>
        </div>

        <div className='chart'>
          <table className='table-bordered table-width'>
            <thead>
              <tr>
                <th className='cell-big'>Urakka</th>
                <th className='cell-mid'>Valmistaja</th>
                <th className='cell-big'>Tuote</th>
                <th className='cell'>GTIN-koodi</th>
                <th className='cell'>Tuotekoodi</th>
                <th className='cell-big'>Tiedosto</th>
                <th className='cell'>Päivämäärä</th>
                <th className='cell-mid'>Erityishuomiot</th>
                <th className='cell-mid'>Varoitusmerkit</th>
                <th className='cell-huge'>Vaaralausekkeet</th>
                <th className='cell-biggest'>Turvalausekkeet</th>
              </tr>
            </thead>

            <tbody>
              {packageState.reportDataLoading && (
                <div className='empty-products'>
                  <div className='loading loading-green'>
                    <div />
                  </div>
                </div>
              )}
              {!packageState.reportDataLoading && products.length === 0 && (
                <div className='empty-products'>Ei tuotteita</div>
              )}
              {!packageState.reportDataLoading &&
                products.length > 0 &&
                products.map((p) => {
                  const data = p.snapshot
                    ? {
                        id: p.snapshot_id,
                        name: p.snapshot_name,
                        talo200: p.snapshot_talo2000,
                        manufacturer: p.snapshot_manufacturer,
                        ean: p.snapshot_ean,
                        safety_codes: p.snapshot_safety_codes,
                        hazard_codes: p.snapshot_hazard_codes,
                        warning_codes: p.snapshot_warning_codes,
                        svhc: p.snapshot_svhc,
                      }
                    : {
                        id: p.data_id,
                        name: p.data_name,
                        talo200: p.data_talo2000,
                        manufacturer: p.data_manufacturer,
                        ean: p.data_ean,
                        safety_codes: p.data_safety_codes,
                        hazard_codes: p.data_hazard_codes,
                        warning_codes: p.data_warning_codes,
                        svhc: p.data_svhc,
                      };
                  return (
                    <tr key={p.id}>
                      <td className='cell-big'>
                        <strong>{p.path}</strong>
                      </td>
                      <td className='cell-mid'>{data.manufacturer}</td>
                      <td className='cell-big'>{data.name}</td>
                      <td className='cell'>{data.ean}</td>
                      <td className='cell'>{p.rt_number}</td>
                      <td className='cell-big'>
                        <FileRows
                          product={p}
                          fileActionCreators={props.fileActionCreators}
                        />
                      </td>
                      <td className='cell'>
                        <FileTimestamps product={p} />
                      </td>
                      <td className='cell-mid'>
                        {data.svhc ? 'Erityistä huolta aiheuttava' : ''}
                      </td>
                      <td className='cell-mid'>
                        <WarningCodes
                          codes={getCodeDescriptions(
                            data.warning_codes,
                            productState.safetyData[productState.langCode]
                              ?.warningCodes,
                            productState.safetyData.en?.warningCodes
                          )}
                        />
                      </td>
                      <td className='cell-huge'>
                        <CodeRows
                          codes={getCodeDescriptions(
                            data.hazard_codes,
                            productState.safetyData[productState.langCode]
                              ?.hazardCodes,
                            productState.safetyData.en?.hazardCodes
                          )}
                        />
                      </td>
                      <td className='cell-biggest'>
                        <CodeRows
                          codes={getCodeDescriptions(
                            data.safety_codes,
                            productState.safetyData.fi?.safetyCodes,
                            productState.safetyData.en?.safetyCodes
                          )}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tfoot>
              <Pagination
                page={page}
                count={total}
                perPage={200}
                handler={(newPage) => {
                  setPage(newPage);
                  props.packageActionCreators.getReportData({
                    search,
                    page: newPage,
                    svhc,
                  });
                }}
              />
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AuthenticatedView(ReportsView);
