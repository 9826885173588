/**
 * ExampleView.js - React component ExampleView
 *
 * Copyright 2015 Taito United Oy
 * All rights reserved.
 */

import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { partition } from 'lodash';

import Footer from '../Footer/Footer';
import PackageDragTarget from '../Draggable/PackageDragTarget';
import ProductDragSource from '../Draggable/ProductDragSource';
import ConfirmActionModal from '../ConfirmActionModal/ConfirmActionModal.tsx';
import CreateButton from './CreateButton.jsx';
import RowLabels from './RowLabels.jsx';
import Bulletins from './Bulletins.jsx';
import MassUpdateCheckboxes from './MassUpdateCheckboxes.tsx';
import dropValidator from '../Draggable/dropValidator';
import { ProductType } from '../../utils/enum';

const messages = defineMessages({
  approvedTooltip: {
    id: 'product-approved-tooltip',
    description: 'Tooltip for approved product',
    defaultMessage: 'Tuotekelpoisuus todettu',
  },

  disapprovedTooltip: {
    id: 'product-disapproved-tooltip',
    description: 'Tooltip for disapproved product',
    defaultMessage: 'Tuotekelpoisuutta ei todettu',
  },

  gwpApprovedTooltip: {
    id: 'product-gwpapproved-tooltip',
    description: 'Tooltip for gwp approved product',
    defaultMessage: 'Hiilijalanjälki todettu',
  },

  gwpDisapprovedTooltip: {
    id: 'product-gwpdisapproved-tooltip',
    description: 'Tooltip for gwp disapproved product',
    defaultMessage: 'Hiilijalanjälkeä ei todettu',
  },

  installedTooltip: {
    id: 'product-installed-tooltip',
    description: 'Tooltip for installed product',
    defaultMessage: 'Asennettu',
  },

  notInstalledTooltip: {
    id: 'product-not-installed-tooltip',
    description: 'Tooltip for not installed product',
    defaultMessage: 'Ei asennettu',
  },

  packageApproveTooltip: {
    id: 'package-approve-tooltip',
    description: 'Tooltip for approve packages',
    defaultMessage:
      'Valitsemalla voit merkitä kaikki tämän ' +
      'urakan tuotteet tuotekelpoisiksi tai poistaa niiden ' +
      'tuotekelpoisuuden kerralla.',
  },

  packageInstallTooltip: {
    id: 'package-install-tooltip',
    description: 'Tooltip for install packages',
    defaultMessage:
      'Valitsemalla voit merkitä kaikki ' +
      'tämän urakan tuotteet asennetuiksi.',
  },

  packageUninstallTooltip: {
    id: 'package-uninstall-tooltip',
    description: 'Tooltip for install packages',
    defaultMessage:
      'Valitsemalla voit perua kaikkien ' +
      'tämän urakan tuotteiden asennuksen.',
  },
});

const ProductDragOperation = {
  COPY: 'COPY',
  MODAL: 'MODAL',
};

const recentlyViewedPackages = (mainNodes) => {
  if (localStorage.getItem('recentlyViewedPackagesToShow')) {
    let latest = JSON.parse(
      localStorage.getItem('recentlyViewedPackagesToShow')
    ).map((id) => mainNodes.find((node) => node.id === id));

    latest = latest.filter((pkg) => pkg !== undefined);
    return latest;
  }

  return [];
};

class PackageHierarchy extends React.Component {
  static propTypes = {
    messageActionCreators: PropTypes.object.isRequired,
    packageActionCreators: PropTypes.object.isRequired,
    productActionCreators: PropTypes.object.isRequired,
    logActionCreators: PropTypes.object.isRequired,
    bulletinActionCreators: PropTypes.object.isRequired,
    packages: PropTypes.object,
    packagesLoading: PropTypes.bool.isRequired,
    package: PropTypes.object,
    packageLoading: PropTypes.bool.isRequired,
    onSelectPackage: PropTypes.func.isRequired,
    product: PropTypes.object,
    productLoading: PropTypes.bool.isRequired,
    products: PropTypes.array,
    packageProducts: PropTypes.object,
    productsLoading: PropTypes.bool.isRequired,
    onSelectProduct: PropTypes.func.isRequired,
    isProductPanel: PropTypes.bool.isRequired,
    bulletins: PropTypes.array,
    bulletinsLoading: PropTypes.bool,
    archivedCount: PropTypes.number,
    openSidePanel: PropTypes.func.isRequired,
    user: PropTypes.object,
    intl: PropTypes.object.isRequired,
    expanded: PropTypes.object,
    activePackage: PropTypes.number,
    memoizedPackages: PropTypes.array,
  };

  state = {
    showArchived: false,
    item: null,
    from: null,
    to: null,
    modalProduct: null,
    productDragOperation: ProductDragOperation.MODAL,
  };

  componentDidMount() {
    document.getElementById('content').addEventListener('scroll', (event) => {
      if (event.target.scrollTop > 100) {
        document.getElementById('header-row').style.display = 'initial';
      } else if (event.target.scrollTop < 100) {
        document.getElementById('header-row').style.display = 'none';
      }
    });
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keyup', this.onKeyUp);

    // If we're navigating to this view from another view, refetch
    // package products if a package has been selected. Otherwise if products have
    // been added to the selected package, the listing will be bugged.
    //
    // TODO: write a sensible package tree and get rid of memoization
    if (this.props.package) {
      this.props.packageActionCreators.getPackageProducts(
        this.props.package.id
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.packagesLoading !== prevProps.packagesLoading) {
      Object.keys(this.props.expanded).forEach((packageId) => {
        const isExpanded = this.props.expanded[packageId];
        if (document.getElementById('collapse' + packageId)) {
          document.getElementById('collapse' + packageId).className = isExpanded
            ? 'collapse in'
            : 'collapse';
        }
        if (document.getElementById('recently-collapse' + packageId)) {
          document.getElementById('recently-collapse' + packageId).className =
            isExpanded ? 'collapse in' : 'collapse';
        }
      });
    }

    if (
      this.props.packagesLoading !== prevProps.packagesLoading &&
      this.props.activePackage !== null
    ) {
      let rootPackageId = this.props.package.id;
      if (this.props.package && this.props.package.level !== 1) {
        rootPackageId = this.props.package.ancestor.id;
      }
      if (
        this.props.activePackage &&
        rootPackageId &&
        document.getElementById('package' + rootPackageId)
      ) {
        const scrollPosition = document.getElementById(
          'package' + rootPackageId
        ).offsetTop;
        document.getElementById('content').scrollTop = scrollPosition - 60;
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keyup', this.onKeyUp);
  }

  onKeyDown = (event) => {
    if (event.key.toLowerCase() === 'control') {
      this.setState({ productDragOperation: ProductDragOperation.COPY });
    }
  };

  onKeyUp = (event) => {
    if (event.key.toLowerCase() === 'control') {
      this.setState({ productDragOperation: ProductDragOperation.MODAL });
    }
  };

  getArchivedPackages = (event) => {
    event.preventDefault();
    this.props.packageActionCreators.getArchivedPackages();

    this.setState({
      showArchived: true,
    });
  };

  handleDrop = () => {
    const { item, to, productDragOperation } = this.state;

    if (!this.props.expanded[to.id]) {
      this.collapseRow(to.id);
    } else {
      this.prepareRow(to.id);
    }

    const isPackage = item && item.level;

    if (!isPackage && productDragOperation === ProductDragOperation.COPY) {
      this.copyPackageProduct();
    } else {
      $('#HomeMovePackageProductModal').modal('show');
    }
  };

  hideArchivedPackages = (event) => {
    event.preventDefault();
    this.setState({
      showArchived: false,
    });
  };

  _productRows = (data) => {
    const elements = [];

    // If the package has products, list them
    if (data.products && data.products.length) {
      const products = (data.products || []).filter((prod) => {
        const variant = prod.data
          ? prod.data.variant
          : prod.snapshot_data
          ? prod.snapshot_data.variant
          : 0;
        return variant !== ProductType.CHEMICAL;
      });

      // eslint-disable-next-line array-callback-return
      products.forEach((prod, index) => {
        /* HACK: most likely a hack to pull the product from product store
         *       to correctly update the product status
         */
        let product = prod;
        const fullyDeleted =
          product.external_id &&
          !product.snapshot &&
          (!product.data || !product.data.id);
        const information =
          product.snapshot && product.snapshot_data
            ? product.snapshot_data
            : product.data || {};
        if (this.props.product && prod.id === this.props.product.id) {
          product = this.props.product;
          products[index] = this.props.product;
        }

        elements.push(
          <ProductDragSource
            key={product.id}
            product={product}
            onDrop={(tgt) => {
              const from = this.props.packages[product.package];
              const to = this.props.packages[tgt.id];

              this.setState(
                {
                  item: product,
                  from: from,
                  to: to,
                },
                this.handleDrop
              );
            }}
          >
            <li
              key={product.id}
              className={classNames('product', {
                deleted: fullyDeleted,
                active:
                  this.props.isProductPanel &&
                  !this.props.productLoading &&
                  this.props.product &&
                  this.props.product.id === product.id,
              })}
              style={{ paddingLeft: '15px' }}
              onClick={() => this.selectProductRow(data, product)}
            >
              <span>
                {information.name ||
                  (information.data && information.data.name) ||
                  'Tuote on poistunut tuotetietokannasta'}
              </span>
              {/* BUG These status labels can overlap with the product name */}
              {this._installabilityCheckbox(product)}

              {this._approvabilityCheckbox(product)}

              {this._gwpCheckbox(product)}

              <div className='PackageHierarchy-row-content---identifier'>
                {information.manufacturer ? (
                  `${information.manufacturer}`
                ) : information.data && information.data.manufacturer ? (
                  information.data.manufacturer
                ) : (
                  <br />
                )}
              </div>
            </li>
          </ProductDragSource>
        );
      });
    }

    return elements;
  };

  // TODO: refactor to separate component
  _installabilityCheckbox = (product) => {
    const fullyDeleted =
      product.external_id &&
      !product.snapshot &&
      (!product.data || !product.data.id);

    const statusStyle = classNames(
      'PackageHierarchy-row-content-label',
      'label',
      'product',
      'secondary',
      'pull-right'
    );

    const uninstall = (event) => {
      if (event) event.stopPropagation();
      this.props.productActionCreators.uninstallProduct(product).then(() => {
        this.props.logActionCreators.getProductLog(product.id);
      });
    };

    const install = (event) => {
      event.stopPropagation();
      this.props.messageActionCreators.clearMessages();
      this.props.productActionCreators.installProduct(product).then(() => {
        this.props.logActionCreators.getProductLog(product.id);
      });
    };

    return (
      !fullyDeleted && (
        <div className={statusStyle}>
          {product.status === 1 ? (
            <a
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (product.snapshot && (!product.data || !product.data.id)) {
                  this.setState({
                    modalProduct: product,
                  });
                  $('#UninstallDeletedProductModal').modal('show');
                } else {
                  uninstall();
                }
              }}
              data-toggle='tooltip'
              data-placement='bottom'
              title={this.props.intl.formatMessage(messages.installedTooltip)}
            >
              <i className='fa fa-check-square-o fa-2x' />
            </a>
          ) : (
            <a
              onClick={install}
              data-toggle='tooltip'
              data-placement='bottom'
              title={this.props.intl.formatMessage(
                messages.notInstalledTooltip
              )}
            >
              <i className='fa fa-square-o fa-2x' />
            </a>
          )}
        </div>
      )
    );
  };

  // TODO: refactor to separate component
  _approvabilityCheckbox = (product) => {
    const fullyDeleted =
      product.external_id &&
      !product.snapshot &&
      (!product.data || !product.data.id);

    const approvedStyle = classNames(
      'PackageHierarchy-row-content-label',
      'label',
      'product',
      'pull-right'
    );

    const approve = product.approved
      ? (event) => {
          event.stopPropagation();
          this.props.messageActionCreators.clearMessages();
          this.props.productActionCreators.disapproveProduct(product.id);
        }
      : (event) => {
          event.stopPropagation();
          this.props.messageActionCreators.clearMessages();
          this.props.productActionCreators.approveProduct(product.id);
        };

    return !fullyDeleted && product.product_approvable ? (
      <div className={approvedStyle}>
        {product.approved ? (
          <a
            onClick={approve}
            data-toggle='tooltip'
            data-placement='bottom'
            title={this.props.intl.formatMessage(messages.approvedTooltip)}
          >
            <i className='fa fa-check-square-o fa-2x' />
          </a>
        ) : (
          <a
            onClick={approve}
            data-toggle='tooltip'
            data-placement='bottom'
            title={this.props.intl.formatMessage(messages.disapprovedTooltip)}
          >
            <i className='fa fa-square-o fa-2x' />
          </a>
        )}
      </div>
    ) : (
      <div className={approvedStyle}>
        <a data-toggle='tooltip' data-placement='bottom' />
      </div>
    );
  };

  _gwpCheckbox = (product) => {
    const gwpApprovedStyle = classNames(
      'PackageHierarchy-row-content-label',
      'label',
      'product',
      'pull-right'
    );

    const approveGwp = (event) => {
      event.preventDefault();
      event.stopPropagation();

      this.props.messageActionCreators.clearMessages();
      this.props.productActionCreators.approveProductCarbonFootprint(
        product.id,
        !product.gwp_approved
      );
    };

    return (
      !!product.gwp_limit &&
      this.props.package &&
      this.props.package.gwp_can_approve && (
        <div className={gwpApprovedStyle}>
          {product.gwp_approved ? (
            <a
              onClick={approveGwp}
              data-toggle='tooltip'
              data-placement='bottom'
              title={this.props.intl.formatMessage(messages.gwpApprovedTooltip)}
            >
              <i
                className='fa fa-check-square-o fa-2x'
                style={{ color: '#109d34' }}
              />
            </a>
          ) : (
            <a
              onClick={approveGwp}
              data-toggle='tooltip'
              data-placement='bottom'
              title={this.props.intl.formatMessage(
                messages.gwpDisapprovedTooltip
              )}
            >
              <i
                className='fa fa-square-o fa-2x'
                style={{ color: '#109d34' }}
              />
            </a>
          )}
        </div>
      )
    );
  };

  selectProductRow = (pkg, product) => {
    // TODO: Could we use data fetched for the package product list?
    // Seems dumb to do a separate request for an individual product.
    this.props.packageActionCreators.getPackageProduct(pkg.id, product);
    this.props.logActionCreators.clearLog();
    this.props.logActionCreators.getProductLog(product.id);

    if (product.external_id) {
      this.props.productActionCreators.getExternalProduct(product);
    }
    this.props.onSelectProduct(pkg);
  };

  // So this renders the project/package table recursively.
  _packageRow = (data) => {
    const elements = [];
    const toggleClasses = classNames({
      fa: true,
      'fa-angle-up': this.props.expanded[data.id],
      'fa-angle-down': !this.props.expanded[data.id],
      'fa-lg': true,
    });

    const doubleToggleClasses = classNames({
      fa: true,
      'fa-angle-double-up': this.props.expanded[data.id],
      'fa-angle-double-down': !this.props.expanded[data.id],
      'fa-lg': true,
    });

    // Differentiate construction sites in the list
    const packageClasses = classNames({
      'PackageHierarchy-construction-site': !data.parent,
      'PackageHierarchy-has-children': data.num_descendants > 0,
      'PackageHierarchy-row': true,
      'row-flex': true,
      'align-vert': true,
      active:
        !this.props.isProductPanel &&
        !this.props.packageLoading &&
        this.props.activePackage === data.id,
      incomplete: !data.is_archived && data.state === 0,
      complete: !data.is_archived && data.state === 2,
      archived: data.is_archived || data.state === 3,
    });

    elements.push(
      <li
        key={'package' + data.id}
        id={'package' + data.id}
        className={packageClasses}
      >
        <div
          className='PackageHierarchy-row-content'
          onClick={this.openPanel.bind(this, data.id)}
        >
          <div className='row-flex'>
            {data.children.length ||
            this.props.packages[data.id].approved !== null ? (
              <i
                className={toggleClasses}
                style={{
                  padding: '0 10px 0 10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={this.collapseRow.bind(this, data.id)}
              />
            ) : null}
            {data.children.length ||
            this.props.packages[data.id].approved !== null ? (
              <i
                className={doubleToggleClasses}
                style={{
                  paddingRight: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={this.collapseNestedRow.bind(this, data.id)}
              />
            ) : null}
            <span
              style={
                data.children.length ||
                this.props.packages[data.id].approved !== null
                  ? null
                  : { marginLeft: '15px' }
              }
            >
              {data.name}
            </span>
            <span>{`(${data.company.name})`}</span>

            <RowLabels data={data} />

            {this.state.activePackage === data.id &&
              this.props.productsLoading && (
                <div className='loading loading-sm loading-green m-l' />
              )}
          </div>
        </div>

        <MassUpdateCheckboxes
          packages={this.props.packages}
          packageProducts={this.props.packageProducts}
          data={data}
          packageActionCreators={this.props.packageActionCreators}
        />
      </li>
    );

    elements.push(
      <ul
        key={`collapse${data.id}`}
        className='collapse'
        id={`collapse${data.id}`}
      >
        {data.children.map(this._packageRow)}
        {this._productRows(data)}
      </ul>
    );

    return (
      <PackageDragTarget
        key={data.id}
        package={data}
        dropValidator={(type, item, target) =>
          dropValidator(type, item, target, data, this.props.packages)
        }
        onOver={() => {
          if (!this.props.expanded[data.id]) {
            this.collapseRow(data.id);
          }
        }}
        onDrop={(tgt) => {
          const to = this.props.packages[tgt.id];

          this.setState(
            {
              item: data,
              from: null,
              to,
            },
            this.handleDrop
          );
        }}
      >
        {elements}
      </PackageDragTarget>
    );
  };

  // This renders recent project/package table recursively
  _recentlyPackageRow = (data) => {
    const elements = [];
    const toggleClasses = classNames({
      fa: true,
      'fa-angle-up': this.props.expanded[data.id],
      'fa-angle-down': !this.props.expanded[data.id],
      'fa-lg': true,
    });

    const doubleToggleClasses = classNames({
      fa: true,
      'fa-angle-double-up': this.props.expanded[data.id],
      'fa-angle-double-down': !this.props.expanded[data.id],
      'fa-lg': true,
    });

    // Differentiate construction sites in the list
    const packageClasses = classNames({
      'PackageHierarchy-construction-site': !data.parent,
      'PackageHierarchy-has-children': data.num_descendants > 0,
      'PackageHierarchy-row': true,
      'row-flex': true,
      'align-vert': true,
      active:
        !this.props.isProductPanel &&
        !this.props.packageLoading &&
        this.props.activePackage === data.id,
      incomplete: !data.is_archived && data.state === 0,
      complete: !data.is_archived && data.state === 2,
      archived: data.is_archived || data.state === 3,
    });

    elements.push(
      <li key={data.id} className={packageClasses}>
        <div
          className='PackageHierarchy-row-content'
          onClick={this.openPanel.bind(this, data.id)}
        >
          <div className='row-flex'>
            {data.children.length ||
            this.props.packages[data.id].approved !== null ? (
              <i
                className={toggleClasses}
                style={{
                  padding: '0 10px 0 10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={this.collapseRow.bind(this, data.id)}
              />
            ) : null}
            {data.children.length ||
            this.props.packages[data.id].approved !== null ? (
              <i
                className={doubleToggleClasses}
                style={{
                  paddingRight: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={this.collapseNestedRow.bind(this, data.id)}
              />
            ) : null}
            <span
              style={
                data.children.length ||
                this.props.packages[data.id].approved !== null
                  ? null
                  : { marginLeft: '15px' }
              }
            >
              {data.name}
            </span>
            <span>{`(${data.company.name})`}</span>

            <RowLabels data={data} />

            {this.state.activePackage === data.id &&
              this.props.productsLoading && (
                <div className='loading loading-sm loading-green m-l' />
              )}
          </div>
        </div>

        <MassUpdateCheckboxes
          packages={this.props.packages}
          packageProducts={this.props.packageProducts}
          data={data}
          packageActionCreators={this.props.packageActionCreators}
        />
      </li>
    );

    elements.push(
      <ul
        key={`recently-collapse${data.id}`}
        className='collapse'
        id={`recently-collapse${data.id}`}
      >
        {data.children.map(this._recentlyPackageRow)}
        {this._productRows(data)}
      </ul>
    );

    return (
      <PackageDragTarget
        key={data.id}
        package={data}
        dropValidator={(type, item, target) =>
          dropValidator(type, item, target, data, this.props.packages)
        }
        onOver={() => {
          if (!this.props.expanded[data.id]) {
            this.collapseRow(data.id);
          }
        }}
        onDrop={(tgt) => {
          const to = this.props.packages[tgt.id];

          this.setState(
            {
              item: data,
              from: null,
              to,
            },
            this.handleDrop
          );
        }}
      >
        {elements}
      </PackageDragTarget>
    );
  };

  openPanel = (packageID, event) => {
    event.preventDefault();

    this.props.packageActionCreators.setActivePackage(packageID);
    this.props.onSelectPackage(packageID);

    this.props.packageActionCreators.openMobileMenu();

    this.props.logActionCreators.clearLog();
    this.props.logActionCreators.getPackageLog(packageID);

    this.props.packageActionCreators.getPackage(packageID);
    this.props.packageActionCreators.getPackageProducts(packageID);

    this.props.packageActionCreators.getChemicalChartInfo(packageID);
  };

  _setAddToParent = () => {
    this.props.onSelectPackage(null);
    this.props.packageActionCreators.setAddToParent(null);
  };

  prepareRow = (packageID) => {
    this.props.packageActionCreators.setActivePackage(packageID);
    this.props.logActionCreators.clearLog();
    this.props.logActionCreators.getPackageLog(packageID);
    this.props.packageActionCreators.getPackage(packageID);
    this.props.packageActionCreators.getPackageProducts(packageID);
    this.props.packageActionCreators.getChemicalChartInfo(packageID);
    this.props.onSelectPackage(packageID);
  };

  collapseRow = (packageId, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const isExpanded = this.props.expanded[packageId];
    if (!isExpanded) {
      this.prepareRow(packageId);
    }
    this.props.packageActionCreators.toggleExpanded(packageId);
    $(`#collapse${packageId}`).collapse(!isExpanded ? 'show' : 'hide');
    $(`#recently-collapse${packageId}`).collapse(!isExpanded ? 'show' : 'hide');
  };

  collapseNestedRow = (packageId, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const isExpanded = this.props.expanded[packageId];

    const subpackages = Object.values(this.props.packages)
      .filter((p) => p.path?.includes(packageId))
      .map((p) => p.id);

    const collapsePath = [packageId, ...subpackages];

    if (!isExpanded) {
      this.props.packageActionCreators.expandSubpackages(packageId);

      this.prepareRow(packageId);

      for (const id of collapsePath) {
        if (id !== packageId) {
          this.props.packageActionCreators.getPackageProducts(id);
        }

        $(`#collapse${id}`).collapse('show');
        $(`#recently-collapse${id}`).collapse('show');
      }
    } else {
      this.props.packageActionCreators.collapseSubpackages(packageId);

      for (const id of collapsePath) {
        $(`#collapse${id}`).collapse('hide');
        $(`#recently-collapse${id}`).collapse('hide');
      }
    }
  };

  movePackageProduct = () => {
    if (!this.state.item.level) {
      this.props.packageActionCreators.movePackageProduct(
        this.state.item.id,
        this.state.item.package,
        this.state.to.id
      );
    } else {
      this.props.packageActionCreators.movePackage(
        this.state.item.id,
        this.state.to.id
      );
    }

    this.setState({
      item: null,
      from: null,
      to: null,
    });
  };

  copyPackageProduct = () => {
    const { item, to } = this.state;
    this.props.packageActionCreators.copyPackageProduct(to.id, item.id);

    this.setState({
      item: null,
      from: null,
      to: null,
    });
  };

  render() {
    const [archivedPackages, activePackages] =
      !this.props.packagesLoading && this.props.packages
        ? partition(this.props.memoizedPackages, 'is_archived')
        : [null, null];

    let modalInfo = null;
    if (this.state.item && this.state.to) {
      modalInfo = this.state.item.level ? (
        <FormattedMessage
          id='info-message'
          description='Info message'
          defaultMessage={`Olet siirtämässä urakan {item} ja
              kaikki siihen kuuluvat tuotteet sekä aliurakat urakkaan
              {target}
            `}
          values={{
            item: <b>{this.state.item.name}</b>,
            target: <b>{this.state.to.name}</b>,
          }}
        />
      ) : (
        <FormattedMessage
          id='info-message-product'
          description='Info message'
          defaultMessage={`Olet siirtämässä tuotetta {item}
              urakasta {from} urakkaan {to}.
            `}
          values={{
            item: <b>{this.state.item.data.name}</b>,
            from: <b>{this.state.from.name}</b>,
            to: <b>{this.state.to.name}</b>,
          }}
        />
      );
    }

    return (
      <div id='content' className='PackageHierarchy col-xs-12 col-sm-8'>
        <ConfirmActionModal
          id='HomeMovePackageProductModal'
          title={
            this.state.item && this.state.item.level
              ? 'Siirrä urakka'
              : 'Siirrä tuote'
          }
          info={modalInfo}
          onConfirm={({ actionName }) => {
            switch (actionName) {
              case 'copyProduct':
                this.copyPackageProduct();
                break;
              default:
                // 'moveProduct'
                // 'confirm' (default ConfirmActionModal confirm action name)
                this.movePackageProduct();
            }
          }}
          confirmActions={
            this.state.item && this.state.item.level
              ? []
              : [
                  <input
                    type='submit'
                    name='moveProduct'
                    className='btn btn-success'
                    value='Siirrä tuote'
                    key='confirm-move-product'
                  />,
                  /* TODO: reenable when fixing copying
                  <input
                    type='submit'
                    className='btn btn-success'
                    name='copyProduct'
                    value='Kopioi tuote'
                    key='confirm-copy-product'
                  />,
                  */
                ]
          }
        />
        <ConfirmActionModal
          id='UninstallDeletedProductModal'
          title='Peru asennus'
          info={
            <FormattedMessage
              id='uninstall-info-message'
              description='Uninstall info message'
              defaultMessage={`Tuote on poistunut tuotetietokannasta.
              Et voi enää muuttaa/nähdä tuotteen tietoja jos perut
              tuotteen asennuksen.
              `}
            />
          }
          onConfirm={() => {
            if (!this.state.modalProduct) return;
            this.props.productActionCreators
              .uninstallProduct(this.state.modalProduct)
              .then(() => {
                this.props.logActionCreators.getProductLog(
                  this.state.modalProduct.id
                );
              });
          }}
        />
        <div id='header-row' className='PackageHierarchy-content'>
          {/* TODO: Why is this thing here twice? */}
          <div
            id='header-content'
            className='PackageHierarchy-header-legend col-xs-12 no-padding'
          >
            <small>
              <i className='PackageHierarchy-header-legend--incomplete' />{' '}
              keskeneräinen
              <i className='PackageHierarchy-header-legend--complete' /> valmis
            </small>
            <small className='pull-right'>
              hiilijalanjälki | tuotekelpoisuus | asennettu
            </small>
          </div>
        </div>

        <div className='PackageHierarchy-content'>
          <div id='bullets'>
            <Bulletins
              bulletins={this.props.bulletins}
              bulletinsLoading={this.props.bulletinsLoading}
            />
          </div>

          <div
            id='header-row-crollable'
            className='PackageHierarchy-header row'
          >
            <CreateButton
              user={this.props.user}
              setAddToParent={this._setAddToParent}
            />

            <div className='PackageHierarchy-header-legend col-xs-12 no-padding'>
              <small>
                <i className='PackageHierarchy-header-legend--incomplete' />{' '}
                keskeneräinen
                <i className='PackageHierarchy-header-legend--complete' />{' '}
                valmis
              </small>
              <small className='pull-right'>
                hiilijalanjälki | tuotekelpoisuus | asennettu
              </small>
              <div className='clearfix' />
            </div>
          </div>

          <div>
            {activePackages &&
              recentlyViewedPackages(activePackages).length > 0 && (
                <h3 className='PackageHierarchy-header-title'>
                  <FormattedMessage
                    id='all-packages'
                    description='Package hierarchy header'
                    defaultMessage='Viimeksi tarkastellut urakat'
                  />
                </h3>
              )}

            {/* Recently viewed packages */}
            {!this.props.packagesLoading && this.props.memoizedPackages && (
              <ul className='row-margins'>
                {recentlyViewedPackages(this.props.memoizedPackages).map(
                  this._recentlyPackageRow
                )}
              </ul>
            )}

            {activePackages &&
              recentlyViewedPackages(activePackages).length > 0 && (
                <h3 className='PackageHierarchy-header-title'>
                  <FormattedMessage
                    id='all-packages'
                    description='Package hierarchy header'
                    defaultMessage='Kaikki urakat'
                  />
                </h3>
              )}

            {/* All (active) packages */}
            {!this.props.packagesLoading && activePackages ? (
              <ul className='row-margins'>
                {activePackages.map(this._packageRow)}
              </ul>
            ) : (
              <div className='loading loading-green loading-center m-t-md' />
            )}

            {!this.props.archivedCount ? null : (
              <div className='m-b'>
                <small>
                  <FormattedMessage
                    id='archived-package-count'
                    description='Text explaining how many archived packages user has'
                    defaultMessage='Sinulla on myös {count, number} arkistoitua työmaata.'
                    values={{
                      count: this.props.archivedCount,
                    }}
                  />
                  &nbsp;
                  {this.state.showArchived ? (
                    <a href='#' onClick={this.hideArchivedPackages}>
                      <FormattedMessage
                        id='archived-packages-hide-link'
                        description={
                          'Link for hiding archived packages. ' +
                          'Comes after archived-package-count.'
                        }
                        defaultMessage='Piilota ne tästä.'
                      />
                    </a>
                  ) : (
                    <a href='#' onClick={this.getArchivedPackages}>
                      <FormattedMessage
                        id='archived-packages-link'
                        description={
                          'Link for showing archived packages. ' +
                          'Comes after archived-package-count.'
                        }
                        defaultMessage='Näytä ne tästä.'
                      />
                    </a>
                  )}
                </small>
              </div>
            )}

            {!archivedPackages ||
            !Object.keys(archivedPackages).length ||
            !this.state.showArchived
              ? null
              : [
                  <h4 key='header' className='m-t m-b'>
                    Arkistoidut urakat
                  </h4>,
                  <ul key='body'>{archivedPackages.map(this._packageRow)}</ul>,
                ]}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default injectIntl(PackageHierarchy);
